@import '../../../styles/variables.scss';
.dependent-quest{
    .title-txt{
        position: relative;
        top: 30px;
    }
    .text-area{
        position: relative;
        top: 1px;
    }
    .depent-quest-table{
        .tabulator {
            border: 1px solid #e9e9e9;
            .tabulator-header{
                background: #e7e6e6 0% 0% no-repeat padding-box;
                border-bottom: none;
                padding: 8px 20px;
                .tabulator-col{
                    border-right: none;
                    .tabulator-col-content{
                        .tabulator-col-title{
                            color: $aws-darkBlack;
                            font-weight: normal;
                        }
                    }
                }
                .tabulator-col.tabulator-sortable:hover{
                    background-color: transparent;
                }
                .tabulator-header-filter{
                    width: 99%;
                    [type=search]{
                        border: none;
                        background-color: transparent;
                        border-bottom: 1px solid $aws-grey;
                        font-size: 12px;
                        line-height: 1;
                        padding: 0 !important;
                        background-image: url('../../../images/search.svg');
                        background-repeat: no-repeat;
                        background-position: right 0;
                        background-size: 14px;
                    }
                    [type=search]:hover,
                    [type=search]:focus{
                        outline: none;
                        background: none;
                    }
                }
            }
            .tabulator-tableHolder{
                max-height: calc(100vh - 475px);
                height: auto;
                .tabulator-table{
                    width: 100%;
                    .tabulator-row {
                        border-bottom: 1px solid #d8d8d8;
                        min-height: 40px;
                        .tabulator-cell{
                            border-right: none;
                            padding: 8px 20px;
                            color: $aws-grey;
                            .tabulator-col-resize-handle{
                                display: none;
                            }
                        }
                        .tabulator-cell:last-child{
                            text-align: center;
                        }
                    }
                    .tabulator-row.tabulator-selectable:active,
                    .tabulator-row.tabulator-selectable:hover{
                        background-color: #d8d8d8;
                    }
                    .tabulator-row.tabulator-selected{
                        background-color: #d8d8d8;
                    }
                    .tabulator-row.tabulator-row-even {
                        background-color: $aws-white;
                    }
                    .tabulator-row:last-child{
                        border-bottom: none;
                    }
                }
                
            }
            .tabulator-tableHolder::-webkit-scrollbar{
                width: 5px;
            } 
            
        }
    }   
}
