/*add question ppr*/
@import '../../../styles/variables.scss';
.addquestion-ppr{
    .header-wraper{
        background-color: $aws-adminpopupHeaderBg;
    }
    .permission-wraper{
        border: 1px solid $aws-lightGrey;
        border-top: none;
        float: left;
        width: 100%;
        padding: 5px 0 5px 20px;
        .table-content{
            width: 22.3%;
            float: left;
        }
        .table-content:first-child{
            width: 33%;
        }
       
    }
    .body-wraper{
        .radio-group{
            width: 67%;
        }
    }
    .radiobtn-wraper{
        li:last-child{
            padding-left: 16px;
        }
    }
    .header-container{
        border-top: 1px solid $aws-lightGrey;
        padding: 15px 0 15px 20px;
    }
    .tabcontent-wraper{
        height: calc(100vh - 325px);
        overflow-y: auto;
    }
    
}
.Polaris-Modal-Dialog__Modal {
    max-width: 100rem !important;
}
