@import '../../../styles/variables.scss';
.AWStextarea-div{
    .style-label{
        font-family: AmazonEmberRegular;
        font-size: 14px;
        color: $aws-darkBlack;
        margin-bottom: 14px;
    }
    textarea{
        background: $aws-white 0% 0% no-repeat padding-box;
        font-family: AmazonEmberRegular;
        resize: none;
        height: 90px;
        border-width: .5px;
    }
    .awsui-textarea{
        border-radius: 4px !important;
    }

}