@page {
    size: auto ;   /* auto is the initial value */  
  }
  @media print {
    .main-container{
      height: auto !important;
      overflow: visible !important;
    }
    html, body {
        width: 1366px;
      }
  }