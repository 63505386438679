@import '../../../styles/variables.scss';
.submenu-wraper{
    .awsui-button.awsui-button-variant-normal,
    .awsui-button.awsui-button-variant-normal:hover,
    .awsui-button.awsui-button-variant-normal:active{
        background: transparent !important;
        border-color: $aws-grey !important;
    }
    .awsui-button-dropdown-item:hover:not(.awsui-button-dropdown-item-disabled){
        border-color: transparent !important;
    }
    .awsui-button.awsui-button-no-text{
        padding-left: 0.6rem !important;
        padding-right: 0.6rem !important;
    }
}
