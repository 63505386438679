@import '../../../styles/variables.scss';
.table-wraper{
  .tabulator{
    background: transparent;
    border: .5px solid $aws-tableBorderColor;
    .tabulator-header{
      color: $aws-lightGrey;
      background-color: transparent;
      font-weight: normal;
      border-bottom: .5px solid $aws-tableBorderColor;
      .tabulator-col{
        [type=search]{
          background-color: transparent;
          border: none;
          border-bottom: .5px solid $aws-grey;
          color: $aws-white;
          padding: 0 !important;
          background-image: url('../../../images/search_grey.svg');
          background-repeat: no-repeat;
          background-position: right;
          background-size: 14px;
        }
        [type=search]:hover,
        [type=search]:focus{
          outline: none;
          background: none;
          border-bottom: .5px solid $aws-primaryColor;
        }
        .tabulator-col-content{
          .tabulator-arrow{
            top: 12px;
            right: 4px;
          }
        }
      }
      .tabulator-col.tabulator-sortable[aria-sort=desc]{
        .tabulator-col-content{
          .tabulator-arrow{
            border-top: 6px solid $aws-white;
          }
        }
      } 
      .tabulator-col{
        border-right: .5px solid $aws-tableBorderColor;
        background: transparent;
        height: 60px !important;
        padding: 3px 10px;
        .tabulator-col-group-cols{
          .tabulator-col{
            border-right: none;
          }
        }
      }
      .tabulator-col:last-child{
        border-right: none;
      }
      .tabulator-col.tabulator-col-group{
        .tabulator-col-group-cols{
          border-top: none;
        }
      }
      .tabulator-col.tabulator-sortable:hover{
        background: transparent;
      }
    }
    .tabulator-tableHolder{
      max-height: calc(100vh - 430px);
      height: auto;
      .tabulator-table{
        color: $aws-white;
        background-color: transparent;
        .tabulator-row{
          background: transparent;
          .tabulator-cell{
            border-right: .5px solid $aws-tableBorderColor;
            padding: 5px 10px;
          }
          .tabulator-cell.clone-border{
            border-right: none;
            padding-left: 15px;
          }
          .tabulator-cell.close-border{
            border-right: none;
            padding-left: 10px;
            .table-close-icon{
              width: 12px;
              .awsui-icon.awsui-icon-size-normal svg{
                height: 12px !important;
              }
              .awsui-icon svg *{
                stroke: $aws-white !important;
              }
            }
          }
          .tabulator-cell.delete-border{
            padding-right: 20px;
            border-right: none;
            
          }
        }
        .tabulator-row.tabulator-row-even{
          background-color: rgba(255,255,255,.1)
        }
        .tabulator-row.tabulator-selectable:hover{
          background-color: $aws-tableHoverColor;
        }
      }
    }
    .tabulator-tableHolder::-webkit-scrollbar{
      width: 5px;
    } 
  } 
}

.status-circle{
  height: 12px;
  width: 12px;
  border-radius: 10px;
  margin: 0 auto;
  display: block;
  top: 0;
  position: relative;
}
