@import '../../../styles/variables.scss';
.admin-table{
  
  .awsui-table-container > table,
  .awsui-table-header-copy{
      background-color: transparent !important;
  }
  .awsui-table-container > table thead > tr > *,
  .awsui-table-header-copy thead > tr > *{
      background-color: #e7e6e6 !important;
      font-size: 16px !important;
  }
  .awsui-table-container > table{
      color: $aws-darkBlack !important;
  }
  .awsui-table-container > table > tbody > tr > td{
      border: 1px solid $aws-lightGrey;
      border-right: none !important;
      border-left: none !important;
  }
  .awsui-table-container > table > tbody > tr > td:first-child{
      border-left: 1px solid $aws-lightGrey !important;
  }
  .awsui-table-container > table thead > tr > * .awsui-table-header-content,
  .awsui-table-container > table thead > tr > *{
      color: $aws-darkBlack !important;
      font-weight: normal !important;
      line-height: 0 ;
      border-top: .5px solid $aws-lightGrey;
      border-left: .5px solid $aws-lightGrey;
      padding: 15px 10px !important;
  }
  .awsui-table-header-content{
      font-weight: normal !important;
  }
  .awsui-table-container > table > tbody > tr > td{
      // border-bottom: none !important;
      padding: 0 10px !important;
      border-bottom: 0.5px solid $aws-lightGrey !important;
  }

  .awsui-table-container > table thead > tr > *{
      border-bottom: 1px solid $aws-lightGrey !important;
      padding: 0 !important;
      // border-left: none !important;
  }
  .awsui-table-container > table thead > tr > :not(:first-child):before{
      border-left: none !important;
  }
  .awsui-table-container > table,
  .awsui-table-container > table > tbody > tr > td:last-child{
      border-right: 1px solid $aws-lightGrey !important;
  }
  .awsui-table-header-copy thead > tr > th{
      padding: 4px 0px !important;
      line-height: 0 !important;
      font-weight: normal;
  }
  .awsui-table-sorting-icon{
    .awsui-icon.awsui-icon-variant-normal{
        position: relative;
        top: -4px;
        
    }
    .awsui-icon svg *{
        stroke: $aws-grey !important;
    }
  }
   
  .icons-styles{
        background-repeat: no-repeat;
        padding: 8px 6px;
        background-size: 12px;
        float: left;
        margin-left: 5px;
        margin-top: 4px;
    }
    .delete-icon{
        background-image: url('../../../images/delete_darkblack.svg');
    }
    .close-icon{
      background-image: url('../../../images/close_icon.svg');
    }
    .edit-icon{
        background-image: url('../../../images/edit_black.svg');
        background-size: 100% 100%;
        padding: 12px;
        margin-top: 0;
    }
}
.role-table,
.watchlist-table,
.user-infor-table,
.ppr-table{
    .admin-table {
        awsui-table .awsui-table-container > table thead > tr:last-child th{
            width: 100px;
        }
    }
}
.global-limit-table{
  .admin-table{
    .awsui-table-container > table thead > tr > *,
    .admin-table .awsui-table-header-copy thead > tr > *{
      font-size: 12px !important;
    }
  }
 }