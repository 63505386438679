@import '../../../styles/variables.scss';
header{
    z-index: 999;
    max-width: 100%;
    background: $aws-darkBlack;
    border-bottom: 1px solid $aws-darkBlack;
    position: absolute;
    width: 100%;
    padding: 5px 0;
    .logo{
        a{
            float: left;
            height: 64px;
            overflow: hidden;
            line-height: 64px;
            white-space: nowrap;
            text-decoration: none;
            .logo-img{
                height:32px;
                position: relative;
                top: 16px;
            }
            .falcon-txt{
                position: relative;
                top: -1px;
            }
            .logo-title{
                color: $aws-white;
                font-family: AmazonEmberRegular;
                // font-weight: bold;
                font-size:18.5px;
                padding: 5px 15px;
                border: none;
                font-size: 21px;
                position: relative;
                top: -1.7px;
            }
            .logo-sub-title{
                color: $aws-darkBlack;
                font-size: 18px;
            }
        }
        
    }
    .notification-user-details{
        .user-icon{
            width: 23px;
            height: 22px;
        }
        .notification {
            cursor: pointer;
            img{
                width: 24px;
                height: 24px;
            }
            .notification-count{
                background: $aws-primaryColor;
                min-width: 15px;
                min-height: 15px;
                z-index: 9999;
                display: inline-block;
                position: relative;
                top: -8px;
                left:-12px;
                border-radius: 50%;
                color: $aws-white;
                font-size: 10px;
                text-align: center;
                line-height: 16px;
            }
        }
    }
    .down-arrow{
        img{
            position: relative;
            top: -0.3px;
        }
    }
    .home-icon{
        .user-icon{
            height: 21px;
        }
    }
    .error-icon{
        position: relative;
        top: -2px;
        img{
            width: 24px;
        }
    }
    .user-name-div:hover .logout-div {
        display: block;
    }
    .user-name-div{
        padding-top: 1px !important;
    }
    .user-icon.ticket-icon{
        height: 18px;
    }
    // .ticket-icon {
    //     height: 21px;
    //     width: 21px;
    // }

    // .wisdom-icon{
    //     height: 20.6px;
    // }
    // .video-icon{
    //     height: 22.2px;
    //     position: relative;
    //     top: 0.6px
    // }
   
    

    
        .user-icon.home-icon{
            height: 20.8px;
            top: -.3px;
        }
        .ticket-icon {
            height: 20px;
            width: 23px;
        }
    
        .wisdom-icon{
            height: 20px;
            margin-top: .3px;
            // width: 23px;
        }
           
        .video-icon{
            width: 23px;
            height: 22px;
            margin-top: 0;
        }
}
.logo-hover{
    position: relative;
    .logo-tooltip{
        position: absolute;
        right: 10px;
        top: -12px;
        background-color: $aws-white;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        display: none;
        z-index: 1;
        width: max-content;
    }
    // .ticketing-tooltip{
    //     right: 155px;
    // }
    // .wisdom-tooltip{
    //     right: 188px;
    // }
    // .training-tooltip{
    //     right: 226px;
    // }
    // .home-tooltip{
    //     right: 268px !important;
    // }
    
   

}
.onboarding-left{
    padding-left: 1.5rem !important;
}
.other-user{
    padding-left:43px !important;
}
.logo-hover:hover > .logo-tooltip{
    display: block;
}
