@import '../../styles/variables.scss';
.core-tab{
    img{
        max-width: 100%;
        // position: relative;
        // top: -10px;
    }
    // .header-wraper header{
    //     border-bottom: none;
    // }
    .tabs-button-wraper{
        ul{
            margin: 0;
            li{
                width: auto;
            }
        }
    }
    .main-container{
        background-color: #f2f2f2;
        .report-leftnav{
            .nav-wrapper{
                .nav-container{
                    width: 580px;
                }
            }
            h4{
                margin-right: 20px;
            }
            .nav-wrapper li .nav-txt{
                text-align: right !important;
                cursor: pointer;
                width: 43%;
            }
            .nav-wrapper li .active-nav{
                margin-right: 20px;
            }
        }
        .generate-btn{
            .btns {
                .Polaris-Button {
                    min-width: 168px;
                }
            }
        }
        .date-wraper{
            position: relative;
            .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name,
            .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__day{
                width: 2.5rem;
            }
        }
        .leftnav-wraper{
            border-radius: 0 20px 20px 0;
            padding: 30px 40px 30px;
            width: 24%;
            z-index: 101;
            background: transparent linear-gradient(118deg, #415876 0%, #293647 49%, #1D2837 100%) 0% 0% no-repeat padding-box;
            li{
                list-style: none;
                color: $aws-lightGrey;
                text-align: right;
                padding-bottom: 20px;
                font-size: 16px;
            }
            li:last-child{
                padding-bottom: 0;
            }
            li.active-txt{
                color: $aws-primaryColor;
                font-size: 18px;
            }
            
        }
        .right-container{
            padding-left: 2%;
            position: relative;
            left: 24%;
        }
        .print-icon{
            img{
                width: 16px;
            }
        }
        .specialized-table,
        .deal-table-wraper{
            tr > th,
            tr > td{
                
                border: 0.5px solid #AAB1BA;
                border-top: none;
                border-bottom: none;
                padding: 6px 10px;
                text-align: center;
                font-size: 12px;
            }
            tr:nth-child(1) th:nth-child(1),
            tr:nth-child(2) td:nth-child(1),
            tr:nth-child(1) td:nth-child(1){
                text-align: left;
            }
            tr:nth-child(odd){
                background: #FFFFFF 0% 0% no-repeat padding-box;
            }
            tr:nth-child(even) {
                background: #F2F2F2 0% 0% no-repeat padding-box;
            }
            tr.total-row{
                background: #CECECE80 0% 0% no-repeat padding-box;
            }
        
    }
    .specialized-table{
        tr > th,
        tr > td{
            padding:6px 14.8px;
        }
    }
    }
    
    
    
}
