@import '../../../styles/variables.scss';
.collapase-wraper{
    .collapse-tab{
        background-color: $aws-admintabBg;
        color: $aws-darkBlack;
        cursor: pointer;
    }
    .awsui-icon svg *{
        stroke: $aws-darkBlack !important;
    }
    .awsui-icon svg .filled{
        fill: $aws-darkBlack !important;
    }
}
