@import '../../../styles/variables.scss';

.list-style {
  background: rgba(206, 206, 206, 0.22) 0% 0% no-repeat padding-box;
  padding-left: 2px;
}
.list-style li {
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 0;
  margin-bottom: 0;
  height: 17px;
}
.list-head {
  font-family: AmazonEmberBold;
  margin-bottom: 0;
  margin-top: 25px;
}
.close-icon {
  cursor: pointer;
}

.attach-wraper {
  // border-bottom: 1px solid $aws-grey;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  .close-summary{
    position: absolute;
    top: 14px;
    right: 0;
  }
  .files-lists{
      height: 320px;
      border-right: 1px solid $aws-grey;
  }
  .files-lists:last-child{
    border: none;
  }
  .AWStextarea-div {
    textarea {
      background: transparent;
    }
    .Polaris-TextField--multiline > .Polaris-TextField__Input {
      height: 185px !important;
      color: $aws-white;
    }
    .Polaris-TextField__Backdrop {
      border: 1px solid $aws-grey;
      background: transparent;
    }
   
  }
    .download-replace{
      float: right;
      width: auto;
    }
  .Polaris-DropZone {
    background: transparent;
  }

  .btns .Polaris-Button {
    color: $aws-primaryColor;
    background: transparent;

    span {
      color: #ff9d00;
    }
  }

  .upload-txt {
    font-size: 12px;
    color: white !important;
  }

  .upload-txt span {
    font-size: 14px;
    text-decoration: underline;
    color: #2d84de !important;
  }

  .upload-container {
    height: 150px;
  }

  .list-item {
    background: #566476 0% 0% no-repeat padding-box;
    color: white;
    .attachedfile-name{
        word-break: break-word;
        width: 78%;
        padding-right: 10px;
        // .file-name{
        //   border-bottom: 1px solid $aws-white;
        // }
    }
    .close:not(:disabled):not(.disabled):hover,
    .close{
      opacity: 1;
    }
    
  }
  .attach-txt{
    color: $aws-primaryColor !important;
  }
  
  .border-left{
      border-left: 1p solid $aws-grey;
  }
  .btns .Polaris-Button.Polaris-Button--disabled {
    background: transparent;

    span {
      color: #ff9d00;
    }
  }

  .Polaris-DropZone::after {
    border-radius: 15px;
  }

  .Polaris-DropZone--sizeLarge {
    min-height: 120px;
  }

  .Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover {
	  cursor: pointer;
	  background-color: transparent;
  }

  li span {
    color: white;
  }

  span {
    text-decoration: none;
    color: $aws-lightGrey;
    float: initial !important;
  }

  .border-right-grey {
    border-right: .5px solid $aws-grey;
  }

  .btns .Polaris-Button {
    color: $aws-primaryColor;
    background: transparent;
  }
  .replace-button {
    img {
      width: 12px;
    }
  }
}