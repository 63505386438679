@import '../../styles/variables.scss';
.workload-wraper{
    .filter-wraper{
        // max-height: calc(100vh - 170px);
        // height: auto;
        // overflow-y: auto;
        li{
            margin-top: 14px;
        }        
    }
    .timeframe-datepicker{
        position: relative;
        .totxt{
            position: absolute;
            top: 10px;
        }
        .date-wraper .react-datepicker-popper .react-datepicker .react-datepicker__triangle{
            right: 24px;
        }
    }

    table{
        width: 100%;
        border: 1px solid $aws-lightGrey;
        thead{
            tr{
                background-color: #f2f2f2;
            }
        }
        th,td{
            padding: 6px 10px;
            text-align: left;
            border-right: .5px solid $aws-lightGrey;
        }
        tbody{
            tr:nth-child(odd){
                background-color: $aws-white;
            }
            tr:nth-child(even){
                background-color: #f2f2f2;
            }
        }
            
    }
}

/* Below CSS will be used only if we are going to use Tabulator*/
/*    .admin-table {
        .tabulator {
            .tabulator-header {
                border: 0.5px solid $aws-lightGrey;
                background-color: white !important;
                .tabulator-col{
                    background: $aws-white;
                    height: 50px !important;
                    padding: 0px 10px;
                    padding-right: 0;
                    width:33.33% !important;
                    min-width:33.33% !important;
                    max-width:33.33% !important;
                    border-top: 1px solid $aws-lightGrey;
                    [type=search]{
                        background-color: transparent;
                        border: none;
                        border-bottom: .5px solid $aws-lightGrey;
                        color: $aws-darkBlack;
                        padding: 0 !important;
                        background-image: url('../../images/search_grey.svg');
                        background-repeat: no-repeat;
                        background-position: right;
                        background-size: 14px;
                        font-size: 12px;
                      }
                      [type=search]:hover,
                      [type=search]:focus{
                        outline: none;
                        background: none;
                        border-bottom: .5px solid $aws-primaryColor;
                      }
                    .tabulator-col-content {
                        padding: 2px 4px;
                        border-right: 0.5px solid $aws-lightGrey;
                        .tabulator-col-title{
                            font-size: 12px;
                            font-family: AmazonEmberBold;
                        }
                    }
                    .tabulator-header-filter{
                        width: 93%;
                    }
                    
                }
                .tabulator-col.tabulator-sortable:hover{
                    background: $aws-white;
                }
            }
            .tabulator-table{
                width:100% !important;
                min-width:100% !important;
                max-width:100% !important;
            }
            .tabulator-tableHolder{
                overflow-y: overlay;
                border-right: 1px solid $aws-lightGrey;
                
                .tabulator-table {
                    border-top: none;
                    .tabulator-row {
                        max-width:100% !important;
                        border: .5px solid $aws-lightGrey;
                        border-bottom: none;
                        .tabulator-cell{
                            width:33.33% !important;
                            min-width:33.33% !important;
                            max-width:33.33% !important;
                            border-right: .5px solid $aws-lightGrey;
                        }
                    }

                    .tabulator-row:last-child{
                        border-bottom: .5px solid $aws-lightGrey;
                    }
                    .tabulator-row.tabulator-row-even{
                        background: $aws-white;
                    }
                }
            }
            .tabulator-footer{
                border-top: .5px solid $aws-lightGrey;
                padding-right: 0;
                width: 100%;
                margin-right: 0;
                padding-bottom: 0;
                .tabulator-calcs-holder{
                    border-top: none;
                    width: calc(100% + 9px);
                    margin: -5px -9.4px 5px;
                    .tabulator-row{
                        .tabulator-cell{
                            width:33.33% !important;
                            min-width:33.33% !important;
                            max-width:33.33% !important;
                            padding: 5px 10px;
                            border-right: .5px solid $aws-lightGrey;
                        }
                        .tabulator-cell:first-child{
                            border-left: .5px solid $aws-lightGrey;
                        }
                        .tabulator-cell:last-child{
                            border-left: 1px solid $aws-lightGrey;
                        }
                    } 
                }
            }
            .tabulator-calcs-holder:only-child{
                border-bottom: 1px solid $aws-lightGrey !important;
            }
        }
    }*/

