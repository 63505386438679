@import '../../../styles/variables.scss';

awsui-radio-group{
    display: inline-block !important;
    width: 100% !important;
    .awsui-radio-button-label{
        cursor: pointer;
    }
    awsui-radio-button{
        width: 32.3% !important;
        display: inline-block !important;
        padding-left: 16% !important;
    }
}

.awsui .awsui-radio-button-styled-circle-checked{
    stroke: #ff9d00;
    fill: #fff;
}