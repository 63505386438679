@import '../../../styles/variables.scss';
.copy-table{
    // margin-top: 1.5rem!important;
thead{
    tr{
        margin-bottom: 10px;
    th{
        color: #cecece;
    font-size: 10px;
    width: 39px !important;
    font-weight: normal;
    border: .1px solid #4C5664;
    &.widthregionSummary{
        width: 53.6px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
       
      
}
    }
    }

}

tbody{
    tr{
        td{
            background-color: #CECECE;
            font-size: 10px;
            width: 39px !important;
            font-weight: normal;
            border: .5px solid #4C5664;
            height: 20px;
             font{
                color: #1d2837 !important;
             }
        }
    }
}

    tr{
        display: flex;
        margin-bottom: 5px;
        td{
        padding: 0 !important; 
        }
    }
}

.regional-traffic-table{
    tr{
        
        td.width9p3{
            width: 53.6px !important;
        }
    }
}
/*data transfer table*/
.data-transfer{
    tbody tr td,
    thead tr th{
        width: 100% !important;
    }
}
.copy-table thead tr th.widthregionSummary{
    width: 55.5px !important;
}
.copy-table thead tr th.widthregionSummary:first-child {
    width: 52px !important;
}

.regional-traffic-table tr td.width9p3 {
    width: 55.5px !important;
}
/*regional Usage*/
.regional-usage-table .data-transfer tbody tr td,
.regional-usage-table .data-transfer thead tr th{
    width: 55px !important;
}
/*withtier*/
.withtier .data-transfer tbody tr td,
.withtier .data-transfer thead tr th{
    width: 39.8px !important;
}
/*without tier*/
.withouttier .data-transfer tbody tr td,
.withouttier .data-transfer thead tr th{
    width: 49px !important;
}
.regional-traffic-table tr td.width9p3:first-child{
    width: 52px !important;
}
.additional-table{
    p{
        line-height: 32px;
    }
    table{
        tr:nth-child(1){
            td{
            background-color: rgba(255,255,255,0.1);
            }
        }
    }
}
@media only screen and (device-width: 1600px){
    .withtier .data-transfer tbody tr td, 
    .withtier .data-transfer thead tr th {
        width: 48.3px !important;
    }
    .withouttier .data-transfer tbody tr td,
    .withouttier .data-transfer thead tr th {
        width: 58.9px !important;
    }
    .regional-usage-table .data-transfer tbody tr td,
    .regional-usage-table .data-transfer thead tr th {
        width: 65.9px !important;
    }
    .copy-table thead tr th.widthregionSummary {
        width: 67.8px !important;
    }
    .regional-traffic-table tr td.width9p3 {
        width: 67.8px !important;
    }
    .request-fee-table .data-transfer tbody tr td,
    .request-fee-table .data-transfer thead tr th{
        width: 53px !important;
    }
}
@media only screen and (device-width: 1980px){
    .withtier .data-transfer tbody tr td, 
    .withtier .data-transfer thead tr th {
        width: 61.8px !important;
    }
    .withouttier .data-transfer tbody tr td, 
    .withouttier .data-transfer thead tr th {
        width: 75.2px !important;
    }
    .regional-usage-table .data-transfer tbody tr td,
    .regional-usage-table .data-transfer thead tr th {
        width: 84.1px !important;
    }
    .copy-table thead tr th.widthregionSummary {
        width: 88px !important;
    }
    .regional-traffic-table tr td.width9p3 {
        width: 88px !important;
    }
    .request-fee-table .data-transfer tbody tr td,
    .request-fee-table .data-transfer thead tr th{
        width: 67.5px !important;
    }
}
.additional-table{
    table{
        font-size: 10px;
        tbody{
            tr:nth-child(odd) td{
                background-color: rgba(255,255,255,0.1);
            }

        }

    }

}

 

@media only screen and (device-width: 1280px){

    .withouttier .data-transfer tbody tr td, .withouttier .data-transfer thead tr th {

        width: 45.6px !important;

    }

    .withtier .data-transfer tbody tr td, .withtier .data-transfer thead tr th {

        width: 37px !important;

    }

    .regional-usage-table .data-transfer tbody tr td, .regional-usage-table .data-transfer thead tr th {

        width: 51px !important;

    }

    .data-transfer thead tr th.widthregionSummary {

        width: 50.8px !important;

    }

    .regional-traffic-table tr td.width9p3 {

        width: 50.8px !important;

    }
    .request-fee-table .data-transfer tbody tr td,
    .request-fee-table .data-transfer thead tr th{
        width: 40.7px !important;
    }
}