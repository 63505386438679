@import '../../../styles/variables.scss';
.alert-wraper{
    background-color: $aws-white;
    box-shadow: 0px 1px 2px #00000029;
    position: relative;
    border: 1px solid $aws-primaryColor;
    border-left: 6px solid $aws-primaryColor;
    .alert-txtwraper{
        display: table;
        height: 40px;
        .grey{
            display: table-cell;
            vertical-align: middle;
        }
    }
    .alert-icon{
        position: relative;
        top: 6px;
        left: 14px;
        .awsui-icon.awsui-icon-size-normal svg {
            width: 1.8rem;
            height: 30px;
        }
        .awsui-icon svg *{
            stroke: $aws-primaryColor !important;
        }
        .awsui-icon.awsui-icon-variant-normal {
            color: $aws-primaryColor;
            margin-right: 10px;
        }
    }
}
