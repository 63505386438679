@import '../../styles/variables.scss';
.sales-report{
    ul{
        border: 1px solid $aws-lightGrey;
    }
    li{
        display: flex;
        width: 100%;
        div{
            padding: 6px 10px;
            // height: 30px;
        }
        div:first-child{
            border-right: 1px solid $aws-lightGrey;
        }
        div:nth-child(2){
            border-right: 1px solid $aws-lightGrey;
        }
    }
    li:nth-child(odd){
        background-color: $aws-white;
    }
    li:nth-child(even){
        background-color: #f2f2f2
    }
    table{
        width: 100%;
        border: 1px solid $aws-lightGrey;
        thead{
            tr{
                background-color: #f2f2f2
            }
        }
        th,td{
            padding: 6px 10px;
            text-align: left;
            border-right: .5px solid $aws-lightGrey;
        }
        tbody{
            tr:nth-child(odd){
                background-color: $aws-white;
            }
            tr:nth-child(even){
                background-color: #f2f2f2
            }
        }
       
    }
}
.salesreport-wraper.mothlyactivity-wraper{
    .filter-wraper{
        max-height: unset;
        overflow-y: unset;
        li{
            float: left;
            width: 100%;
        }
    }
    .timeframe-datepicker{
        position: relative;
        .totxt{
            position: absolute;
            top: 10px;
        }
        .date-wraper .react-datepicker-popper .react-datepicker .react-datepicker__triangle{
            right: 24px;
        }
    }
    .salesbtn-group{
        width: auto;
        li{
            margin-right: 0 ;
        }
        li:first-child{
            padding-top: .5rem;
        }

    }
} 

.print-download{
    display: flex;
}
.small-s-space{
    padding-left: 1px;
}