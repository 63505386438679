@import  "../../../styles/variables.scss";

// .marker-wraper{
//     height: 0;
//     cursor: pointer;
//     width: 99%;
//     display: flex;
// }
// .mumeric-slider{
//   .dot-wraper-bar{
//     top: -11px;
//   }
    
// }
// .dot-wraper{
//   left: -1px;
//   top: -10px;
//   position: absolute;
// }
// .marks-position{
//   text-align: right;
//   // width: 25%;
//   color: $aws-grey;
// }

// .black-dot{
//   width: 0px;
//   font-size: 30px;
//   position: relative;
//   color: #707070;
//   cursor: pointer;
//   top: -6px;
//   position: absolute;
//   padding-top: 0rem !important;
// }
// .marks_point3{
//   position: relative;
//   top: 8px;
//   right: 0px;
//   font-size: 14px;
// }
// .dot-wraper{
//   li:last-child{
//     right: 0;
//     left: 5px;
//   }
// }
// .btm-txt{
//   li:nth-child(1){
//     left: 0;
//   }
//   li{
//     position: relative;
//     left: 30px;
//   }
// }

//   .sliderWithTextValue{
//     .Polaris-RangeSlider-SingleThumb{
//       .Polaris-RangeSlider-SingleThumb__Output{
//         display: none;
//       }
//     }
//     .dot-wraper-bar{
//       top: -10px;
//     }
//   }
//   .textslider-btmtxt.btm-txt 
//     {
//       li:nth-child(1),
//       li{
//         left: unset;
//         text-align: center;
//       }
//       li:nth-child(1){
//         text-align: left;
//       }
//       li:last-child{
//         text-align: right;
//       }
//   }
// .textslider-dot {
//   li:nth-child(1),
//       li{
//         left: unset;
//         text-align: right;
//       }
//       li:nth-child(1){
//         text-align: left;
//       }
//       li:last-child{
//         text-align: right;
//         left: unset;
//       }
// }
// .mumeric-slider {
//   position: relative;
//  }
//  .marks_point{
//   margin-left: 0;
// }
//  .marks_point:first-child{
//    margin-left: 0;
//  }
//  .dot-wraper-bar {
//   left: -1px;
//   position: relative;
// }
// .dot-wraper-bar-full-width {
//   width: 100% ;
// }

/*ant slider*/
.ant-slider-step{
  background: rgba(206, 206, 206, 0.5607843137254902);
}
.ant-slider-track{
  background-color: $aws-primaryColor;
}
.ant-slider-dot{
  background-color: $aws-grey;
  border:none;
  top: -1px;
  width: 6px;
  height: 6px;
}
.ant-slider-dot-active{
  border: none;
  background-color: $aws-grey;
}

.ant-slider-handle{
  width: 20px;
  height: 20px;
  margin-top: -9px;
  border: solid 2px $aws-primaryColor;
}
.ant-slider-handle:focus{
  border-color: $aws-primaryColor;
  box-shadow: none;
}
.ant-slider:hover .ant-slider-track{
  background-color: $aws-primaryColor;
}
.ant-slider-handle.ant-tooltip-open,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
  border-color: $aws-primaryColor;
}
.ant-slider-mark{
  top: 20px;
}
.textslider-wraper{
  .ant-slider-mark{
    .ant-slider-mark-text:first-child{
      left: 2% !important;
    }
  }
}
.disable-state{
  .ant-slider-dot{
    background-color: rgba(112, 112, 112, 0.47);
  }
  .ant-slider-dot-active{
    background-color: rgba(112, 112, 112, 0.54);
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
      border-color: rgba(255, 157, 0, 0.419)
  }
  .ant-slider-handle{
    border: solid 2px rgba(255, 157, 0, 0.419)
  }
  .ant-slider-track {
    background-color: rgba(255, 157, 0, 0.32);
  }
  .ant-slider:hover .ant-slider-track {
    background-color: rgba(255, 157, 0, 0.45);
  }
}
