@import '../../../styles/variables.scss';
.drag-wraper {
    a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover,
    a:not([href]):not([tabindex]),a{
        color: $aws-darkBlue;
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
    }
    .has-error{
        position: relative;
        top: -3px;
       
    }
    .awsui-button{
        .awsui-icon.awsui-icon-size-normal svg{
            height: 14px !important;
        }
    }
    .awsui-icon svg *{
        stroke: $aws-primaryColor !important;
    }
    .close-icon{
        position: relative;
        top: -2px;
    }
    .error-icon{
        .Polaris-Icon{
            position: relative;
            top: 4px;
            height: 1.2em;
            width: 1.4rem;
            .Polaris-Icon__Img{
                fill: $aws-red;
            }
        }      
    }
    .Polaris-DropZone-FileUpload__Button:focus,
    .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--focused {
        border-color: $aws-primaryColor;
        box-shadow: 0 0 0 1px $aws-primaryColor
    }
    .Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled):after {
        border-color: $aws-primaryColor
    }
    .Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled):after ,
    .Polaris-DropZone__Overlay {
        border: .2rem dashed $aws-primaryColor;
    }
    .filter-green{
        filter: invert(78%) sepia(16%) saturate(1093%) hue-rotate(191deg) brightness(99%) contrast(13%);
    }
    .Polaris-DropZone--sizeExtraLarge{
        min-height: 8.5rem;
    }

    .attachmentHeading{
        padding-top: 16px;
        color: #1d2837;
        margin-right: 1%;
    }

    .attachLabel{
        color: $aws-primaryColor !important;
    }

    .rightAttachLabel{
        right: 11px;
    }
    .greyColorLink{
        color: $aws-grey !important;
    }
}
