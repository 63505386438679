@import '../../styles/variables.scss';
.mothlyactivity-wraper{
    .filter-wraper{
        // max-height: calc(100vh - 170px);
        // height: auto;
        // overflow-y: auto;
        li{
            margin-top: 14px;
            float: left;
            width: 100%;
            .awsui-select-dropdown-option-selectable{
                margin-top: 0px !important;
            }
        }
    }
    .monthly-table{
        border: .5px solid $aws-lightGrey;
        li{
            display: flex;
            span{
                padding:4px 10px;
            }
        }
        li span:first-child{
           width: 80%; 
           border-left: 0.5px solid $aws-lightGrey;
           border-right: 0.5px solid $aws-lightGrey;
        
        }
        li span:last-child{
            width: 20%;
            border-right: 0.5px solid $aws-lightGrey;
        }
        li:nth-child(odd) span{
            background-color: $aws-white;
        }
        li:nth-child(even) span{
            background-color: #f2f2f2;
        }
        li:first-child span{
           font-family: AmazonEmberBold;
        }
        li:nth-child(5),
        li:nth-child(6),
        li:nth-child(7){
          span:first-child{
             font-family: AmazonEmberBold;
           }
        }
    }

    table{
        width: 100%;
        border: 1px solid $aws-lightGrey;
        thead{
            tr{
                background-color: #f2f2f2;
            }
        }
        th,td{
            padding: 6px 10px;
            text-align: left;
            border-right: .5px solid $aws-lightGrey;
        }
        // tr:nth-child(1){
        //     font-weight: bold;
        // }
        tbody{
            tr:nth-child(odd){
                background-color: $aws-white;
            }
            tr:nth-child(even){
                background-color: #f2f2f2;
            }
        }
       
    }
    .timeframe-datepicker{
        position: relative;
        .totxt{
            position: absolute;
            top: 10px;
        }
        .date-wraper .react-datepicker-popper .react-datepicker .react-datepicker__triangle{
            right: 24px;
        }
    }

}
.export-ppr-report{
    .timeframe-datepicker{
        position: relative;
        .totxt{
            position: absolute;
            top: 10px;
        }
        .date-wraper .react-datepicker-popper .react-datepicker .react-datepicker__triangle{
            right: 24px;
        }
    }
}