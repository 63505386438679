.ppr-bg {
  background: transparent linear-gradient(309deg, #c172ea 0%, #0c60ed 100%) 0%
    0% no-repeat padding-box;
}
.ppr-submitted-bg {
  background: transparent linear-gradient(312deg, #f5cb3f 0%, #fc8a76 100%) 0%
    0% no-repeat padding-box;
}
.sales-bg {
  background: transparent linear-gradient(313deg, #91e274 0%, #03bdc4 100%) 0%
    0% no-repeat padding-box;
}
.pending-service-bg {
  background: transparent linear-gradient(310deg, #d9828c 0%, #be70f7 100%) 0%
    0% no-repeat padding-box;
}
.pending-excutable-bg {
  background: transparent linear-gradient(128deg, #1bb172 0%, #0b56fd 100%) 0%
    0% no-repeat padding-box;
}
.fully-excuted-bg {
  background: transparent linear-gradient(121deg, #0dc461 0%, #90d345 100%) 0%
    0% no-repeat padding-box;
}
.card-wraper div {
  padding: 2px 10px;
  border-radius: 8px;
  position: relative;
  float: left;
  height: 130px;
  cursor: pointer;
  .count-txt {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 90%;
  }
}
.other-logins {
  margin-right: 2.5%;
  width: 18%;
}
.billing-dashboard {
  margin-right: 4.2%;
  width: 47.9%;
}
.card-wraper:last-child {
  .billing-dashboard {
    margin-right: 0;
  }
}

.sales-rep {
  margin-right: 1.98%;
  width: 15%;
}
.card-wraper div.fully-excuted-bg {
  margin-right: 0;
}

.card-helptext{
  background: #344e71;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  padding: 6px 10px !important;
  bottom: -50px;
  white-space: break-spaces;
  opacity: 1;
  display: block;
  pointer-events: none;
  position: absolute !important;
  height: auto !important;
  z-index: 9;
  width: 210px;
  left: -10px;
}

.card-helptext::before {
content: '';
  position: absolute;
  display: block;    
  width: 0px;        
  left: 50%;
  top: 0;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 10px solid #344e71;
  transform: translate(-50%, calc(-100% - 0px));
}
.fully-excuted-bg .card-helptext{
  bottom: -30px;
  width: 200px;
}