@import '../../../styles/variables.scss';
.ratecard-table{
    .tabulator{
        border: 1px solid $aws-lightGrey;
        .tabulator-header {
            border-bottom: 1px solid $aws-lightGrey;
            .tabulator-col {
                .tabulator-col-resize-handle{
                    display: none;
                }
                .tabulator-col-content {
                    .tabulator-col-title{
                        color: $aws-grey;
                        font-weight: normal;
                        text-align: center;
                    }
                }
            }
        }
        .tabulator-tableHolder {
            .tabulator-table{
                background-color: rgba(255, 247, 247, 0.19);
                .tabulator-row{
                    border-bottom: 1px solid $aws-lightGrey;
                    background-color: rgba(255, 247, 247, 0.19);
                    .tabulator-cell{
                        border-right: 1px solid $aws-lightGrey;
                        text-align: center;
                        color: $aws-grey;
                        font-size: 12px;
                        .tabulator-col-resize-handle{
                            display: none;
                        }
                    }
                    .tabulator-cell.tabulator-editing{
                        border: 2px solid $aws-primaryColor;
                        background-color: $aws-white;
                        input{
                            text-align: center;
                        }
                    }
                }
                .tabulator-row.tabulator-selectable:hover{
                    background-color: transparent;
                }
                .tabulator-row.tabulator-row-even{
                    background-color: rgba(255, 247, 247, 0.19);
                }
            }
            
        }
        .tabulator-tableHolder::-webkit-scrollbar{
            width: 5px;
        } 
    } 
}
