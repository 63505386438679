@import '../../../styles/variables.scss';
.dashboard-table{
    margin-top: 6px;
    // .awsui-table-container > table > tbody{
    //     display: inline-flex;
    //     overflow-y: auto;
    //     flex-direction: column;
    //     max-height: calc(100vh - 400px);
    // }
    // .awsui-table-container > table > thead{
    //     display: inherit;
    // }
    
    awsui-table-preferences div[awsui-table-preferences-region=preferences] > span{
        display: block !important;
    }
    awsui-table-content-selector{
        max-width: 100% !important;
        padding-left: 0 !important;
    }
    .awsui-table-content-selector{
        max-width: 100% !important;
        padding-left: 0 !important;
    }
    .awsui-table-container > table{
        margin-bottom : 2px;
    }
    .awsui-table-container > table,
    .awsui-table-header-copy{
       background-color: transparent !important;
    }
    .awsui-table-container > table thead > tr > *,
    .awsui-table-header-copy thead > tr > *{
        background: #1d2837 !important;
    }
    .awsui-table-container > table{
        color: $aws-white !important;
    }
    .awsui-table-container > table > tbody > tr > td{
        border: 1px solid $aws-tableBorderColor;
        border-right: .5px solid $aws-tableBorderColor !important;
    }
    .awsui-table-container > table > tbody > tr > td:first-child{
        border-left: 1px solid $aws-tableBorderColor !important;
    }
    .awsui-table-container > table thead > tr > * .awsui-table-header-content,
    .awsui-table-container > table thead > tr > *{
        color: $aws-white !important;
        font-weight: normal !important;
        line-height: 0 ;
        border-top: .5px solid $aws-tableBorderColor;
        border-left: .5px solid $aws-tableBorderColor;
        padding: 15px 10px !important;
    }
    .awsui-table-container > table > tbody > tr > td{
        border-bottom: none !important;
        padding: 0 10px !important;
    }
    .awsui-table-container > table > tbody > tr:nth-child(even):hover,
    .awsui-table-container > table > tbody > tr:hover{
        background-color: #a06b1c;
    }
    .awsui-table-container > table > tbody > tr:nth-child(even){
       background-color: hsla(0,0%,100%,.1);
    }
    .awsui-table-container > table thead > tr > *{
        border-bottom: 1px solid $aws-tableBorderColor !important;
        padding: 0 !important;
    }
    .awsui-table-container > table thead > tr > :not(:first-child):before{
       border-left: none !important;
    }
    .awsui-table-container > table,
    .awsui-table-container > table > tbody > tr > td:last-child{
       border-right: 1px solid $aws-tableBorderColor !important;
    }
    .awsui-table-container > table > tbody > tr:last-child{
        border-bottom: 1px solid $aws-tableBorderColor !important;
    }

    /*popup*/
    .awsui-modal-dialog.awsui-modal-expandtofit{
        background: $aws-white;
        .awsui-form-field-label{
            float: left;
            width: 100%;
            padding: 10px 20px;
            font-family: AmazonEmberBold;
            border-bottom: .5px solid $aws-lightGrey;
        }
        .awsui-form-field-control {
            max-height: calc(100vh - 238px);
            overflow-y: auto;
        }
        .awsui-form-field-controls{
            padding: 20px !important;
        }
        .awsui-button.awsui-button-variant-link,
        .awsui-button.awsui-button-variant-primary,
        .awsui-button.awsui-button-variant-primary:hover{
            background: $aws-primaryColor !important;
            border-color: $aws-primaryColor !important;
            border-radius: 3px !important;
        }
        .awsui-button.awsui-button-variant-link{
            background: transparent !important;
        }
    }
    // .awsui-table-inner{
    //     top: -30px;
    // }
    /*search icon */
    .awsui-table-regions-container{
        // width: 30%;
        // float: right !important;
        background: transparent !important;
        border-bottom: none !important;
        // position: relative;
        // right: 30px;
        .awsui-table-preferences-trigger{
            margin-right: 0 !important;
            padding-right: 0 !important;
        }
        .awsui-table-tools{
            padding: 0 !important;
        }
        .awsui-icon.awsui-icon-size-normal svg{
            height: 20px;
        }
        .awsui-filtering-results,
        .awsui-input-type-search{
            color: $aws-lightGrey !important;
        }
        .awsui-input-type-search{
            height: 2.7rem;
            border: 0.5px solid $aws-tableBorderColor !important;
            font-size: 12px !important;
            border-radius: 3px !important;
            background-color: transparent !important;
        }
        .awsui-input-icon-left{
            top: 4px !important;
        }
        .awsui-table-filtering-container{
            .awsui-icon.awsui-icon-size-normal svg{
                height: 12px !important;
            }
        }
        .awsui-util-container-footer{
            padding-bottom: 1.4rem !important;
            padding-top: 1.4rem !important;
        }
    }
    .awsui-table-sorting-icon{
        top: 10px !important;
        .awsui-icon.awsui-icon-size-normal svg{
            height: 14px !important;
        }
    }
    .awsui-toggle-native-input{
        cursor: pointer;
    }
    .awsui-table-pagination-current-page{
        color: $aws-lightGrey !important;
    }
    .awsui-table-pagination-content{
        min-width: auto !important;
        overflow-x: inherit !important;
    }
    .awsui-table-heading-container{
        float: left;
        awsui-table-preferences{
            position: absolute;
            right: 36px;
        }
    }
    .youtube-post{
        left: 25px !important;
    }
    .reset-post{
        left: 0 !important;
    }
    .setting-post{
        right: -4px !important;
        padding-right: 35px !important;
    }
    .reset-settingpost{
        right: 30px !important;
    }
    awsui-table-filtering,
    awsui-table-pagination{
        position: relative !important;
    }
    awsui-table-filtering{
        flex: initial !important;
    }
    .awsui-table-preferences-trigger{
        border-left: none !important;
        padding: 0 !important;
    }
    .awsui-input-icon-right{
        .awsui-icon.awsui-icon-size-normal svg{
            top: -2px;
        }

    }
}
 
