@import '../../../styles/variables.scss';
.close-icon{
    .awsui-icon.awsui-icon-size-normal svg{
        height: 14px !important;
        top: -4px !important;
    }
    .awsui-icon svg *{
        stroke: $aws-darkBlack !important;
    }
}
.awsui-modal-body:not(.awsui-modal-expandtofit){
    overflow-y: unset !important;
    max-height: unset !important;
}
.awsui-util-container > :last-child{
    padding-bottom: 0 !important;
}
/*common popup style */
.popup{
    .header-wraper{
        background: $aws-admintabBg;
        .Polaris-Icon{
            height: 1.6rem;
        }
    }
    .footer-wraper{
        border-top: 1px solid $aws-lightGrey;
        .btn-container{
            width: 200px;
        }
    }
    .radio-btnwrpaer{
        .title-txt{
            li{
                display: inline-block;
            }
        }
    }
    .drop-wraper .disinline{
        display: block;
    }
    
}

/*new user*/
.new-user{
    .input-width{
        width: 30.95%;
    }
}
/*add section*/
.addsection-popup-wraper{
    width: 60%;
    margin: 0 auto;
    .add-section-popup{
        background: var(--p-surface-foreground, white);
        box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08);
    }
}
.editpage-header{
    .footer-wraper{
        .btn-container{
            width: 294px;
        }
    } 
}
/*edit section*/
.editsection-popup-wraper{
    width: 60%;
    margin: 0 auto;
    .edit-section-popup{
        background: var(--p-surface-foreground, white);
        box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08);
        .btn-container{
            width: auto;
        }
    }
    
}
.edit-section-popup{
    .tabs-contanier{
        li{
            padding: 0 12px;
            margin-right: 0 !important;
        }
    }
    .quest-wraper{
        max-height: calc(100vh - 320px);
        overflow-y: auto;
    }
}
/*add questin*/
.addques-wraper{
    .addquestion-ppr{
        background: var(--p-surface-foreground, white);
        box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08);
        overflow-x: hidden;
    }
}
/*rate card popup*/
.ratecard-config-popup{
    max-width: 70%;
    margin: 0 auto;
    
    .configuration-popup{
        background: var(--p-surface-foreground, white);
        box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08);
        .header-wraper{
            background: #e7e6e6 0% 0% no-repeat padding-box;
        }
        .AWS-input-div input{
            text-align: center;
            border-radius: 0;
        }
        input::placeholder{
            color: $aws-darkBlack;
        }
        .add-wraper{
            position: relative;
        }
        .input-container{
            position: relative;
            .close-icon{
                position: absolute;
                top: 0;
                z-index: 30;
                right: 0;
                .Polaris-Icon__Svg{
                    fill: $aws-grey;
                }
            }
        }
        .body-wraper{
            overflow-y: auto;
            max-height: calc(100vh - 260px);
            .Polaris-TextField__Input{
                min-height: 2.6rem;
                line-height: 1.4rem;
            }
        }
        .body-wraper::-webkit-scrollbar{
            width: 5px;
        }
        .footer-wraper{
            border-top: 1px solid $aws-lightGrey;
            .warning-icon{
                .Polaris-Icon{
                    position: relative;
                    top: 12px;
                    height: 1.6rem;
                    .Polaris-Icon__Svg{
                        fill: $aws-primaryColor;
                    }
                }
            }
        }
        
    }
}
