@import '../../../styles/variables.scss';
.termination-leftnav-summary{
    
    .urlpath{
        opacity: 0;
        z-index: -9999;
        pointer-events: none;
        position: absolute;
    }
    .close-summary{
        position: absolute;
        right: 0;
        top: 8px;
    }
    .bottom-content,
    .left-content{
        .left-txt{
            color: $aws-lightGrey;
            margin-bottom: 10px;
        }
    }
    .bottom-content{
        border-top: 1px solid $aws-grey;
        .Polaris-TextField{
            color: $aws-white;
        }
        .AWStextarea-div textarea,
        .Polaris-TextField__Backdrop{
            background: transparent;
        }
    }
    select{
        font: 1.2em "Fira Sans", sans-serif !important;
        color: red;
    }
    .scroll-content{
        max-height: calc(100vh - 340px);
        overflow-y: auto;
        height: auto;
    }
    .AWStextarea-div {
        textarea{
            background: transparent;
        }
        .Polaris-TextField--multiline > .Polaris-TextField__Input{
            color: $aws-white;
        }
        .Polaris-TextField__Backdrop{
            border: 1px solid $aws-grey;
            background: transparent;
        }  
    }
    .close-summary{
        position: absolute;
        right: 0;
        top: 10px;
    }
    

    .comment-box{
        // border-top: 1px solid $aws-grey;
        .AWStextarea-div textarea{
            background-color: transparent;
        }
        .awsui-textarea{
            color: $aws-white !important;
        }
    }
}