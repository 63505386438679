@import '../../../styles/variables.scss';
.collaboration-wraper{
    // border-bottom: 1px solid $aws-grey;
    overflow-y: auto;
    max-height: calc(100vh - 250px);
    height: auto;
    .close-summary{
        position: absolute;
        top: 14px;
        right: 0;
      }
      .comment-box{
        // border-top: .5px solid $aws-grey;
        .AWStextarea-div textarea{
            background-color: transparent;
        }
        .awsui-textarea{
            color: $aws-white !important;
        }
    }
    .AWStextarea-div {
        textarea{
            background: transparent;
        }
        .Polaris-TextField--multiline > .Polaris-TextField__Input{
            height: 185px !important;
            color: $aws-white;
        }
        .Polaris-TextField__Backdrop{
            border: 1px solid $aws-grey;
            background: transparent;
        }  
    }

    .right-wraper{
        // border-left: 1px solid $aws-grey;
        .comment-btn{
            .btns .Polaris-Button{
                color: $aws-primaryColor;
                background: transparent;
            }
        }
    }
}
/*attachement tab*/
.attach-wraper{
    span{
        text-decoration: underline;
        color: $aws-lightGrey;
    }
}
.border-right-grey{
    border-right: .5px solid $aws-lightGrey;
}
.m-t-10{
    margin-top: 10px;
}
.scroll-left{
    height: 285px;
    overflow-y: scroll;
}
// .nav-wrapper{
//     height: 480px;
// }