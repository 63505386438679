@import '../../../styles/variables.scss';

.edit-notification-wraper{
    max-width: 55%;
    margin: 0 auto;
    .editnotification-wraper{
        background-color: $aws-white;
        .header-wraper{
            background: #e7e6e6 0 0 no-repeat padding-box;
            .close-icon{
                .Polaris-Icon{
                    height: 1.6rem;
                }
            }
        }
        .body-wraper{
            background-color: $aws-white;
        }
        .body-wraper::-webkit-scrollbar{
            width: 5px;
        }
        .footer-wraper{
            background-color: $aws-white;
            border-top: 1px solid $aws-lightGrey;
            .btn-container{
                width: 200px;
            }
        }
    }
}