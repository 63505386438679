@import "../../../styles/variables.scss";
.tabs-button-wraper {
  li {
    list-style: none;
    color: $aws-grey;
    background-color: $aws-white;
    border: 0.5px solid $aws-lightGrey;
    font-size: 14px;
    padding: 4px 15px;
    border-radius: 0;
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 22%;
    text-align: center;
    border-right: none;
    margin: 0;
    padding-top: 4px !important;
    .tick-img {
      position: absolute;
      top: -12px;
      right: -8px;
      img {
        width: 20px;
      }
    }
  }
  li:first-child {
    border-radius: 4px 0 0 4px;
  }
  li:last-child {
    border-right: 0.5px solid $aws-lightGrey;
    border-radius: 0 4px 4px 0;
  }
  li.active:last-child {
    border-right: 0.5px solid $aws-primaryColor;
  }
  .active {
    border: 0.5px solid $aws-primaryColor;
    background-color: $aws-primaryColor;
    color: $aws-white;
  }
  .disabled-selected {
    background-color: $aws-grey;
    color: $aws-white;
  }
  .disabled {
    background-color: $aws-lightGrey;
    color: $aws-white;
  }
  .disable-state {
    li {
      pointer-events: none;
      background-color: #eaeded;
      border: 0.5px solid #eaeded;
    }
    .active {
      background-color: rgba(255, 157, 0, 0.4);
      border: 0.5px solid rgb(247, 208, 145);
    }
  }
}

.aosButtonWidth {
  width: 370px !important;
  float: left;
}
