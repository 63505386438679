@import '../../../styles/variables.scss';
.snackbar {
  visibility: hidden;
  min-width: 250px;
  max-width: 40%;
  margin-left: 0;
  background-color: $aws-white;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 5001;
  left: 40%;
  top:50%;
  padding: 4px 15px;
  font-size: 14px;
  color: $aws-grey;
  box-shadow: 0 0 0 1px $aws-lightGrey, 0 0 0 0 transparent;
}
  .snackbar:hover{
    box-shadow: 0 0 0 1px $aws-lightGrey, 0 0 0 0 transparent;
  }
    .error{
      color: $aws-red !important;
    }
  
    .show {
      visibility: visible;
      -webkit-animation: new fadein 0.5s, new fadeout 0.5s 2.5s;
      animation: new fadein 0.5s, new fadeout 0.5s 2.5s;
    }
  
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }