@import '../../../styles/variables.scss';
.admin-wraper{
    .three-dot{
        border: 1px solid $aws-lightGrey;
        border-radius: 4px;
        .Polaris-Icon{
            height: 2rem;
        }
    }
    .customer-info-padding{
        max-height: fit-content;
    }
    .nav-wrapper {
        h4{
            width: 90%;
        }
        .nav-container{
            width: 221px;
        }
        li {
            .nav-txt{
                width: 77%;
                cursor: pointer;
                text-align: right !important;
            }
            .active-nav{
                margin-right: 20px;
            }
        }
    }
}
