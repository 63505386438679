@import '../../../styles/variables.scss';
.user-management{
    .sub-controls{
        li{
            display: inline-block;
            cursor: pointer;
            .Polaris-Icon{
                height: 1.6rem;
                position: relative;
                top: 3px;
            }
        }
    }
    .delete-icon-grey{
        background-image: url('../../../images/delete_grey.svg');
    }
    .plus-icon.close-icon .awsui-icon.awsui-icon-size-normal svg{
        top: -2px !important;
    }
}
.user-managenent-table{
    .tabulator{
        .tabulator-header {
            .tabulator-col{
                padding: 8px 10px;
                .tabulator-col-content{
                    padding: 0;
                }
                .tabulator-header-filter{
                    width: 86%;
                    [type=search]{
                        border: none;
                        background-color: transparent;
                        border-bottom: 1px solid $aws-grey;
                        font-size: 12px;
                        line-height: 1;
                        padding: 0 !important;
                        background-image: url('../../../images/search.svg');
                        background-repeat: no-repeat;
                        background-position: right 0;
                        background-size: 14px;
                    }
                    [type=search]:hover,
                    [type=search]:focus{
                        outline: none;
                        background: none;
                    }
                }
                
            }
            .tabulator-col:nth-child(4){
                .tabulator-header-filter{
                    width: 92%;
                }
            }
        }
        .tabulator-tableHolder{
            max-height: calc(100vh - 280px);
            overflow-x: hidden;
        }
        .tabulator-tableHolder::-webkit-scrollbar{
            width: 5px;
          } 
        
    }
}
.user-infor-table{
    .tabulator{
        .tabulator-header {
            .tabulator-col{
                height: 60px !important;
            }
        }
    }
}
.Polaris-Modal-Dialog__Modal{
    box-shadow: none !important;
    background: none !important;
}
/* Delete user popup */
.delete-user{
    width: 500px;
    margin: 0 auto;
    .delete-userpopup {
        background: var(--p-surface-foreground, white);
        box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08);
        .warning-icon{
            .Polaris-Icon{
                position: relative;
                top: 2px;
                    .Polaris-Icon__Svg{
                        fill : $aws-primaryColor;
                    }
                }
            }
    }
}
.addrole-popup-wraper{
    width: 50%;
    margin: 0 auto;
}
.addrole,
.addnewuser-popup{
    background: var(--p-surface-foreground, white);
    box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08);
}
