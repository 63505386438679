@import '../../../styles/variables.scss';
.nav-wrapper{
    border-radius: 0 20px 20px 0;
    position: fixed;
    width: 24%;
    z-index: 9;
    background: transparent linear-gradient(118deg, #415876 0%, #293647 30%, #1D2837 100%) 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 8px 7px 32px -9px rgba(29,40,55,0.5);
    -moz-box-shadow: 8px 7px 32px -9px rgba(29,40,55,0.5);
    box-shadow: 8px 7px 32px -9px rgba(29,40,55,0.5);
    .flag-wraper{
        background: #d3d3d3de 0% 0% no-repeat padding-box;
        box-shadow: 0 1px 2px #00000029;
        height: 34px;
        left: 0;
        p{
            padding-top: 7.5px !important;
        }
        z-index: 8;
        .flag-icon{
            position: relative;
            top: -13.6px;
        }

    }
    .flag-txt{
        .txt{
            border-right: 2px solid $aws-primaryColor;
            padding-right: 5px;
            margin-bottom: 2px;
            width: 64px;
        }
    }
    
    .flag-closed-width{
        max-width: 24.4%;
    }
    .flag-open-width{
        max-width: 99.4%;
    }
    .leftnav-section{
        position: relative;
        top: 50px;
    }
    .nav-container{
        width: 295px;
        float: right;
        height: 391px;
    }
    li{
        list-style: none;
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        span{
            float: left;
        }
        .nav-txt{
            width: 38%;
            text-align: right;
            margin-right: 20px;
            word-break: break-word;
        }
        .nav-step,
        .nav-txt{
            color: $aws-lightGrey !important;
            font-size: 16px;
            float: right;
        }
        .nav-step{
            font-size: 32px;
            margin-right: 10px;
            position: relative;
            top: 10px;
        }
        .active-nav{
            color: $aws-primaryColor !important;
            font-size: 18px;
            // margin-right: 24px;
            margin-right: 15px;
        }
        .active-count{
            color: $aws-primaryColor !important;
            font-size: 40px;
            margin-right: 8px;
            height: 40px;
        }
        .tick-img{
            position: relative;
            top: 8px;
            float: right;
        }
        .active-img{
            top: 8px;
        }
    }
    li:last-child{
        margin-bottom: 0;
    }
    .dot-lines{
        float: right;
        width: 100%;
        text-align: right;
        right: 2px;
        top: 0px;
        position: relative;
        li{
            border-bottom: 4px solid $aws-lightGrey;
            width: 20px;
            height: 0px;
            display: inline-block;
            border-radius: 5px;
            margin-right: 20px;
            margin-bottom: 0;
            padding-top: 0;
        }
        
        li:last-child{
            margin:0;
        }
        .active-line{
            border-bottom: 4px solid $aws-darkGreen;
        }
        .progress-line{
            border-bottom: 4px solid $aws-lightGrey;
        }
        .progress-line-forCNP{
            border-bottom: 4px solid $aws-lightGrey;
        }
    }

    .summary-txt{
        color: $aws-primaryColor;
        float: right;
        text-align: right;
        padding-right: 0;
        font-size: 16px;
        // position: absolute;
        right: 0;
        bottom: 6px;
        cursor: pointer;
        .arrow-img{
            padding-left: 8px;
            img{
                width: 30px;
            }
            // position: relative;
            // left: 10px;
        }
        .close-wraper.arrow-img{
            padding-right: 10px;
        }
    }
    /*admin left nav*/
    .admin-leftnav{

        li{
            width: 160px;
            float: right;
            .nav-txt{
                text-align: right;
            }

        }
    }
    ::-webkit-scrollbar {
        width: 0px;
    }
    .leftnav-downarrows{
        border-top: 1px solid $aws-primaryColor;
        position: relative;
        bottom: 0px;
        // margin-bottom: 20px;
        .Polaris-Icon__Svg{
            fill: $aws-primaryColor;
        }
    }
    .urlpath{
        user-select: none;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10+ and Edge */
        -moz-user-select: none;
    }
}
.nav-wrapper:hover{
    -webkit-box-shadow: 8px 7px 32px -9px rgba(29,40,55,0.5);
    -moz-box-shadow: 8px 7px 32px -9px rgba(29,40,55,0.5);
    box-shadow: 8px 7px 32px -9px rgba(29,40,55,0.5);
}
.lines-wraper{
    float: right;
}
.nav-wrapper-expand{
    width: 97% !important;
    z-index: 101;
    padding-top: 1.30rem !important;
}

.padding-right20{
    padding-right: 20px;
}
.title-on-expand{
    font-size: 20px;
    color: $aws-primaryColor;
    padding-left: 2%;
}

.customer-info-padding{
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    height: auto;
    padding-top: 0;
    // .menu-container {
    //     height: 440px;
    //     overflow-y: scroll;
    //     }
}
.close-wraper{
    .close-arrow{
        transform: rotate(180deg);
        margin-left: 10px;
        width: 25px;
    }
}
.list-style{
    background: rgba(206, 206, 206, 0.22) 0% 0% no-repeat padding-box;
    padding: 1px 2px;
}
.tabs-padding-top{
    padding-top:10px;
}
//for full height of the line in left nav - added by amulya
.full-height{
    height:100%
}
/*big screens*/
@media (min-width: 1920px){
    .nav-wrapper{
        width: 23%;
        .summary-txt{
            position: relative;
            // top: -22px;
        }
    }
}
@media (min-width: 1600px){
    .nav-wrapper{
        width: 23%;
        .summary-txt{
            position: relative;
            // top: -22px;
        }
    }
}

.neglect-page {
    cursor: auto;
	opacity: 0.3;
	pointer-events: none;
}