@import '../styles/print.scss';
@import '../styles/variables.scss';
@import'../styles/firefox.scss';
@font-face {
	font-family: AmazonEmberRegular;
	src: url(fonts/AmazonEmberRegular.ttf);
}

@font-face {
	font-family: AmazonEmberBold;
	src: url(fonts/AmazonEmberBold.ttf);
}

@font-face {
	font-family: AmazonEmberItalic;
	src: url(fonts/AmazonEmberItalic.ttf);
}

@font-face {
	font-family: AmazonEmberBoldItalic;
	src: url(fonts/AmazonEmberBoldItalic.ttf);
}

body {
	margin: 0;
	font-size: 14px;
	font-family: 'AmazonEmberRegular';
	overflow: hidden;
	scrollbar-width: thin;
}

::-webkit-scrollbar {
	height: 8px;
	background-color: transparent;
	width: 5px;
}

::-webkit-scrollbar-thumb {
	background: $aws-grey;
}

.scroll-width5::-webkit-scrollbar {
	width: 5px;
}

.scroll-width0::-webkit-scrollbar {
	width: 0;
}

.scroll-width10::-webkit-scrollbar {
	width: 10px;
}

.tabulator-tableHolder::-webkit-scrollbar,
.summary-scroll::-webkit-scrollbar {
	background: transparent;
}

.tabulator-tableHolder::-webkit-scrollbar-thumb,
.summary-scroll::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: $aws-grey;
}

.tabulator-tableHolder::-webkit-scrollbar-thumb {
	background: $aws-lightGrey;
}


/*font size */

.font-size40 {
	font-size: 40px !important;
}

.font-size30 {
	font-size: 30px !important;
}

.font-size24 {
	font-size: 24px !important;
}

.font-size20 {
	font-size: 20px !important;
}

.font-size18 {
	font-size: 18px !important;
}

.font-size16 {
	font-size: 16px !important;
}

.font-size14 {
	font-size: 14px !important;
}

.font-size12 {
	font-size: 12px !important;
}

.font-size11 {
	font-size: 11px !important;
}

.font-size10 {
	font-size: 10px !important;
}


/* color */

.dark-black {
	color: $aws-darkBlack !important;
}

.grey {
	color: $aws-grey !important;
}

.light-white {
	color: $aws-lightGrey !important;
}

.white {
	color: $aws-white !important;
}

.bg-grey {
	background-color: $aws-lightBgGrey !important;
}

.red {
	color: $aws-red !important;
}

.orange {
	color: $aws-primaryColor !important;
}

.bg-green {
	background-color: $aws-lightGreen;
}

.bg-amber {
	background-color: $aws-primaryColor;
}

.bg-red {
	background-color: $aws-red;
}

.dark-blue {
	color: $aws-darkBlue;
}


/* font family */

.amber-italic {
	font-family: AmazonEmberItalic !important;
}

.amber-bold-italic {
	font-family: AmazonEmberBoldItalic !important;
}

.amber-bold {
	font-family: AmazonEmberBold !important;
}

.amber-regular {
	font-family: AmazonEmberRegular !important;
}

:hover,
:focus {
	outline: none;
	box-shadow: none;
}

.border-bottom-grey {
	border-bottom: 1px solid $aws-lightGrey;
}

a {
	color: unset;
}

a:hover {
	text-decoration: none;
	color: unset;
}

li {
	list-style: none;
}

p {
	margin-bottom: 1rem;
}

.overflow-hidden-y {
	overflow-y: hidden;
}

.overflow-auto-y {
	overflow-y: auto;
}

.full-width {
	float: left;
	width: 100%;
}

.display-flex {
	display: flex;
}

.cursor-pointer {
	cursor: pointer;
}

button:focus {
	outline: none;
}

.red-border {
	border-color: $aws-darkRed;
}

.editable-color {
	color: #2e27ad !important;
}

.pointer-events-none {
	pointer-events: none;
}

.width-auto {
	width: auto;
}

.body+div {
	height: 100%;
}

.overlay-dashboard {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	height: 100%;
	background-color: rgba(41, 41, 41, 0.25) !important;
  
  }

.aisp-toggle {
	width: 29.3%;
	margin-top: 0 !important;
	left: 29%;
  position: relative;
  .toggle-text{
    max-width: 100%;
  }
	.switch-wraper {
		position: relative;
		top: 5px;
	}
	.circle-alert {
		display: none;
	}
}

.main-container {
	height: calc(100vh - 75px);
	float: left;
	width: 100%;
	position: relative;
	top: 75px;
	// margin-bottom: 64px;
	overflow-x: hidden;
}
.main-container::-webkit-scrollbar-thumb{
	border-radius: 20px;
}
.main-container::-webkit-scrollbar {
	width: 10px;
}

.display-none {
	display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	box-shadow: 0 0 0px 1000px $aws-white inset;
	-webkit-box-shadow: 0 0 0px 1000px $aws-white inset;
}

.border-none {
	border: none;
}

.border-left {
	border-left: 1px solid $aws-lightGrey !important;
}

.grey-txt {
	color: $aws-grey;
	font-size: 14px;
}

.comment-info {
	z-index: 9;
}

.error-icon {
	.awsui-icon svg * {
		stroke: $aws-red !important;
	}
}


/*main container position */

.cust-info-wraper {
	position: relative;
	left: 33%;
	h6 {
		font-size: 18px;
		font-family: AmazonEmberBold;
	}
	/* common wrapper for each section*/
	.content-wraper {
		border-bottom: 1px solid $aws-lightGrey;
		h5 {
			font-family: AmazonEmberBold;
		}
		.yes-comment {
			font-size: 14px;
			color: $aws-grey;
		}
		.upload-txt {
			color: $aws-grey;
			font-size: 14px;
			.browse-txt {
				color: $aws-darkBlue;
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.table-count {
			color: $aws-grey;
			font-size: 12px;
			border-radius: 6px;
			width: 5%;
		}
	}
}

[data-lock-scrolling] {
	overflow-y: hidden;
}

.table-info-icon {
	position: absolute;
}

// .circle-alert {
// 	img {
// 		// width: 12px;
// 		margin-left: 5px;
// 		cursor: pointer;
// 		position: relative;
// 		// top: 0;
// 		object-fit: cover !important;
// 		vertical-align: middle;
// 	}
// }
.circle-alert {
	// width: 14px;
	display: inline-block;
	img {
	    margin-left: 5px;
		height: 14px;
		margin-bottom: 1px;
	}
}
.circle-alert{
	cursor: pointer;
	padding-left: 6px;
	.awsui-icon svg{
		fill: #5382fd !important;
	}
	.awsui-icon svg *{
		stroke: #fff !important;
	}
	.awsui-icon.awsui-icon-size-normal svg{
		height: 14px !important;
	}
	.awsui-icon.awsui-icon-size-normal{
		.stroke-linejoin-round{
			stroke-width: 0 !important;
		}
	}
}
.header-youtube-icon{
	.awsui-icon.awsui-icon-size-normal svg{
		border: .071em solid #cecece;
		width: 18px;
		height: 18px;
		margin-top: 2px;
		border-radius: 50%;
		padding: 2px;
		padding-left: 3px;
	}
}

.light-header.header-youtube-icon{
	.awsui-icon.awsui-icon-size-normal svg{
		border: .078rem solid #707070;
	}
	.awsui-icon svg * {
		stroke: #707070 !important;
	}
	.awsui-icon svg .filled{
		fill: #707070 !important;
	}
}
.youtube-icon{
	cursor: pointer;
	.awsui-icon.awsui-icon-size-normal{
		margin-top: 0px;
		display: flex;
	}
	.awsui-icon.awsui-icon-size-normal svg{
		width: 12px;
		background-color: #d45b06;
		padding-left: 0.5px;
		height: 12px;
		border-radius: 100%;
		border: 2px solid #d45b06;
		zoom: normal;
		top: 0;
	}
	.awsui-icon svg .filled{
		fill: #ffff !important;
	}
	.awsui-icon svg * {
		stroke: #fff !important;
	}
}

.requestfeeee{
	.request-fees-youtube{
		display: flex;
		margin-top: 4.1px;
	}
}
.other-pricing-youtube{
	margin-top: 1.1px !important;
}
.pricing-youtube{
	margin-top: 1.3px;
}
.termination-youtube.youtube-icon,
.leftNav-copy-youtube.youtube-icon,
.leftnav-summary-youtube.youtube-icon,
.regional-limits-youtube.youtube-icon,
.additional-dealterm.youtube-icon,
.popup-video-icon.youtube-icon{
	.awsui-icon.awsui-icon-size-normal {
		margin-top: 0;
		margin-left: 1rem;
	}
}
.commit-youtube{
	margin-top: 1.3px;
}
.pricing-youtube.youtube-icon,
.request-fees-youtube.youtube-icon,
.commit-youtube.youtube-icon,
.leftnav-youtube.youtube-icon{
	.awsui-icon.awsui-icon-size-normal {
		margin-top: 0;
	}
}
.dashboard-dropdown{
	.youtube-icon .awsui-icon.awsui-icon-size-normal{
		margin-top: 4px;
	}
}
//1920 & 125%
@media screen and (min-width:1536px) and (max-width:1599px) { 
	.youtube-icon .awsui-icon.awsui-icon-size-normal svg{
		width: 11px;
		height: 11px;
	}
	.circle-alert .awsui-icon.awsui-icon-size-normal svg {
		height: 13px !important;
	}
	.ppr-stages-youtube{
		margin-left: 12px !important;
	}
	.leftnav-youtube{
		margin-right: 0.5rem !important;
		right: 0 !important;
	}
	.nav-wrapper-expand{
		.leftnav-youtube{
			right: 1px !important;
		}
	}
	.leftNav-copy-youtube{
		top: 1.6px;
	}
	.leftnav-tabs .tabs-contanier .leftnav-summary-youtube{
		left: 9px;
	}
	.dashboard-dropdown{
		.youtube-icon .awsui-icon.awsui-icon-size-normal{
			display: table-row-group;
			top: 0.1px;
		}
	}
	.additional-dealterm{
		left: 8px;
	}
	.additional-dealterm.youtube-icon .awsui-icon.awsui-icon-size-normal{
		margin-left: unset;
	}
	.youtube-wraper {
		position: relative;
		top: 3.4px;
		left: 6px;
	}
	.dealinactive-popup{
		.youtube-wraper {
		top: 0.4px;
		}
	}
	.version-popup{
		.youtube-wraper {
			top: 1.4px;
			left: 0;
		}
	}
	.delete-ppr-popup{
		.youtube-wraper{
			top: 0.4px;
			left: 0px;
		}
	}
	.setting-youtube.youtube-icon{
		 .awsui-icon.awsui-icon-size-normal svg{
			top: .5px;
		}
	}
	.pagination-youtube {
		top: 60px !important;
	}
	.deal-wraper .width97 {
		width: 95.4%;
	}
	.deal-wraper .load-btnwraper .awsui-button{
		margin-right: 5px;
	}
}
//1280 & 150%
@media screen and (min-width:1280px) and (max-width : 1343px){ 
	.youtube-icon .awsui-icon.awsui-icon-size-normal svg{
		top: 0.08px;
	}
	.dealtype-youtube{
		.ppr-stages-youtube{
			top: 1.2px;
		}
	}
}
// 1680 & 125%
@media screen and (min-width:1344px) and (max-width : 1365px){ 
	.youtube-icon .awsui-icon.awsui-icon-size-normal svg{
		width: 11.4px;
		height: 11.4px;
	}
	.circle-alert .awsui-icon.awsui-icon-size-normal svg {
		height: 13px !important;
	}
}
//1366 & 100%
@media screen and (min-width:1366px) and (max-width : 1343px) { 
	.youtube-icon .awsui-icon.awsui-icon-size-normal svg{
		width: 12px;
		height: 12px;
	}
}

@media screen and (max-width:1600px) {
	.youtube-icon{
		.awsui-icon.awsui-icon-size-normal{
			// margin-top: -5px;
		}
	}
}
@media screen and (min-width:1979px) {
	.youtube-icon{
		.awsui-icon.awsui-icon-size-normal{
			// margin-top: -5px;
		}
	}
}
@media screen and (max-width:1280px) {
	.calendar-width {
		max-width: 26%;
	}
}

.mapcore-dropdown {
	max-width: fit-content;
}

.display-content {
	display: contents;
}

.deal-detail.pricing-detail.video-icon {
	position: relative;
	top: -5px;
}

.toggle-txt-wraper{
	max-width: 77.2% !important;
}
/*popover*/

.awsui-popover__body--visible {
	background-color: #344e71;
	border-radius: 3px;
	padding: .5rem .9rem;
	border: none;
	z-index: 999;
}

.awsui-popover__arrow .awsui-popover__arrow--position-right-top,
.awsui-popover__arrow.awsui-popover__arrow--position-top-center {
	display: none;
}

.awsui-popover__content {
	color: $aws-white !important;
	font-size: 12px;
}

// payer id input
.payerid-input {
	.awsui-input-container {
		.awsui-input-icon-left {
			display: none;
		}
	}
	.awsui-input.awsui-input-has-icon-left {
		padding-left: 8px !important;
	}
}


/*checkbox disable*/

.awsui-checkbox-disabled {
	pointer-events: none;
}

// .nav-wrapper-expand{
//   .full-height{
//     max-height: calc(100vh - 160px);
//   }
// }
.additional-table{
	line-height: 32px;
	table{
		line-height: 16px;
	}
}
/*media query*/

.youtube-icon {

	img {
		// height: 11.4px;
		// margin-left: 10px;
		cursor: pointer;
		height: 14px;
	}
}

.tooltip-style {
	position: absolute;
	top: 20px;
	background-color: #fff;
	padding: 1px 6px;
	border-radius: 4px;
	font-size: 12px;
	display: none;
}

.leftnav-youtube {
	position: absolute;
  top: -4px;
  right: -26px;
	.leftnav-tooltip {
		z-index: 9;
		width: max-content;
		right: 0;
    top: -21px;
	}
}

.leftnav-youtube:hover>.leftnav-tooltip {
	display: block;
}

.additional-dealterm {
	position: relative;
	top: 1.1px;
	img {
		position: relative;
    top: 0;
    left: 10px;
	}
}

.pricing-detail {
	// height: 11.4px;
	// width: 14px;
	height: 14px;
	cursor: pointer;
}

.deal-detail.pricing-detail.video-icon {
	// height: 11.4px;
}

.ppr-stages-youtube {
  position: relative;
	z-index: 7;
	top: 0;
    margin-left: 1rem;
    img{
      position: relative;
      top: 0;
      left: 10px;
    }
	.ppr-stages-tooltip {
		position: absolute;
		left: 0;
		top: -15px;
		background-color: #fff;
		padding: 1px 6px;
		border-radius: 4px;
		font-size: 12px;
		display: none;
		z-index: 1;
		width: max-content;
	}
	.ppr-deal-desc-tooltip {
		left: 0;
	}
	.select-deal-type-tooltip {
		left: 0;
	}
	.video-icon {
		height: 15px;
	}
}

.dealtype-youtube {
	.ppr-stages-youtube {
		// top: 0;
	}
}

.ppr-stages-youtube:hover>.ppr-stages-tooltip {
	display: block;
}
.toggle-circle-wraper{
    position: relative;
    top: 5px;
}

@media (min-width: 1366px) {
	.aisp-toggle {
		width: 31%;
		left: 28%
	}
}

@media (min-width: 1600px) {
	.aisp-toggle {
		width: 31%;
		left: 28%
	}
}

@media (min-width: 1920px) {
	.aisp-toggle {
		width: 32.4%;
		left: 27.5%;
	}
}

.orangeborder-input {
	.awsui-input {
		border: .5px solid $aws-primaryColor !important;
	}
}