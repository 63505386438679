@import '../../styles/variables.scss';
.page-notfound{
    
    h1{
        font-size: 160px;
        color: $aws-primaryColor;
    }
    .link-txt{
        text-decoration: underline;
        color: $aws-darkBlue;
    }
}