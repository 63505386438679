@import '../../../styles/variables.scss';
.sequence-wraper{
    .sequence-box{
        border: 1px solid $aws-lightGrey;
        padding: 10px;
        margin-right: 6px;
        border-radius: 2px;
        margin-bottom: 10px;
    }
    .selected-bg.sequence-box,
    .sequnce-number.sequence-box{
        padding: 0 10px;
    }
    .filled-bg{
        background-color: rgba(215, 213, 213, 0.43);
    }
    .selected-bg{
        background-color: $aws-primaryColor;
        border: 1px solid $aws-primaryColor;
        .Polaris-Icon__Svg{
            fill: $aws-white;
        }
    }
}
