@import '../../styles/variables.scss';
.generate-pts{
    position: absolute;
    right: 0;
}
 .audit-trail{
    position: absolute;
    width: max-content;
    right: 5px;
    border-radius: 3px;
    margin-top: 10px;
    z-index: 999;
    background-color: $aws-white;
    border: 1px solid $aws-white;
    box-shadow: -2px 4px 4px 0 rgba(32, 42, 53, 0.09), 0 2px 4px 0 rgba(32, 42, 54, 0.08);
    .txt{
        font-size: 12px;
        width: 100%;
        float: left;
        span{
            width: max-content;
        }
    }
    li{
        padding: 5px 10px;
        display: flex;
        width: 100%;
        background-color: $aws-white;
    }
    li:hover{
        background-color: $aws-primaryColor;
        .txt{
            color: $aws-white;
        }
    }
   
    // li:nth-child(odd){
    //     background-color: $aws-white;
    // }
    // li:nth-child(even){
    //     background-color: $aws-primaryColor;
    //     .txt{
    //         color: $aws-white;
    //     }
    // }
}
.audit-trail:hover{
    box-shadow: -2px 4px 4px 0 rgba(32, 42, 53, 0.09), 0 2px 4px 0 rgba(32, 42, 54, 0.08);
}
.post-relat{
    position: relative;
}