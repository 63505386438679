/*-- color variabel--*/

$aws-primaryColor : #ff9d00;
$aws-darkBlack : #1d2837;
$aws-grey : #707070;
$aws-lightGrey : #cecece;
$aws-white : #ffffff;
$aws-red : #ff0000;
$aws-darkRed : #ff3838;
$aws-lightOrange : #ffcc83;
$aws-lightShdowOrange : #f7e9d5;
$aws-darkBlue : #146EB4;
$aws-lightGreen : #90D345;
$aws-darkGreen : #15BF9F;
$aws-lightBgGrey : #f2f2f2;
$aws-tableBorderColor : #747474;
$aws-tableHoverColor : #a06b1c;
$aws-admintabBg : #e7e6e6;
$aws-adminpopupHeaderBg : #e7e6e6;