@import '../../../styles/variables.scss';
.close-icon{
    .awsui-icon.awsui-icon-size-normal svg{
        height: 14px !important;
        top: -4px !important;
    }
    .awsui-icon svg *{
        stroke: $aws-darkBlack !important;
    }
}
 .youtube-wraper{
    position: relative;
    top: 2px;
    .popup-tooltip-style{
        position: absolute; 
        top: -4px;
        left: 30px;
        width: max-content;
        background-color: $aws-white;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        // border: 0.5px solid $aws-lightGrey;
        display: none;
    }
    .popup-tooltip-style.delete-ppr{
        right: 0;
        left: unset;
        top: -20px;
    }
    .video-icon{
        // height: 11.4px;
        cursor: pointer;
        position: relative;
        top: 0;
        height: 14px;
    }
}

.youtube-wraper:hover .popup-tooltip-style{
    display: block;
}

.awsui-modal-overlay{
    background-color: rgba(41, 41, 41, 0.25) !important;
}
.awsui-modal-header{
    display: none !important;
}
.awsui-util-container{
    background-color: transparent !important;
    box-shadow: none !important;
    border-top: none !important;
}
.awsui-modal-body:not(.awsui-modal-expandtofit){
    overflow-y: unset !important;
    max-height: unset !important;
}
.awsui-util-container > :last-child{
    padding-bottom: 0 !important;
}

/*alert popup*/
.alert-popup{
    width: 40%;
    margin: 0 auto;
    .header-txt{
        background-color: $aws-lightGrey;
        padding: 12px 14px;
        border-radius: 0;
    }
    .title-txt{
        color: $aws-primaryColor;
        font-family: AmazonEmberBold;
    }
    .close-icon{
        .Polaris-Icon{
            width: 1.4rem;
            position: relative;
            top: 4px;
        }
    }
    .awsui-modal-dialog{
        max-width: 450px !important;
    }
}
/*global limit */
.global-limit-popup{
    padding: 20px 10px 20px 20px;
    .tabulator{
        text-align: center;
        border: 1px solid $aws-lightGrey;
        .tabulator-row.tabulator-selectable:hover,
        .tabulator-row.tabulator-row-even{
            background-color: transparent;
        }
        .tabulator-header{
            background-color: $aws-lightBgGrey;
            border-bottom: 1px solid $aws-lightGrey;
            .tabulator-col{
                border: 1px solid $aws-lightGrey;
                text-align: center;
            }
        }
        .tabulator-row {
            .tabulator-cell{
                border-right: 1px solid $aws-lightGrey;
                cursor: default;
            }
            .tabulator-cell:last-child{
                border-right: none;
            }
            .tabulator-col-resize-handle{
                display: none
            }
        }
    
    }
    .close-icon{
        .Polaris-Icon{
            width: 1.4rem;
        }
    }
}
/*common popup style */
.Polaris-Modal-Dialog__Modal{
    border-radius: 0 !important;
}
.popup{
    .header-wraper{
        background: $aws-admintabBg;
        .Polaris-Icon{
            height: 1.6rem;
        }
    }
    .footer-wraper{
        border-top: 1px solid $aws-lightGrey;
        .btn-container{
            width: 200px;
        }
    }
    .radio-btnwrpaer{
        .title-txt{
            li{
                display: inline-block;
            }
        }
    }
    
}
/*add question ppr*/
.addquestion-ppr{
    .header-wraper{
        background-color: $aws-lightGrey;
    }
}
/*new user*/
.new-user{
    .input-width{
        width: 30%;
    }
}
.popup-bg{
    background: var(--p-surface-foreground, white);
    box-shadow: none;
    border-radius: 6px;
}
.delete-popup-wraper{
    width: 70%;
    margin: 0 auto;
}
.delete-popup-wraper.dasboard-dealpopup{
    width: 70%;
}
.global-limit-wraper{
    width: 100%;
    margin: auto;
    .global-limit-popup {
        overflow-y: auto;
        max-height: calc(100vh - 120px);
    }
    .awsui-table-container > table > tbody > tr > td{
        border-bottom: none !important;
    }
    .awsui-table-container > table > tbody > tr > td:first-child{
        border-right: 0.5px solid $aws-lightGrey !important;
    }
    .awsui-table-container > table{
        border-right: none !important;
    }
}
.termination-popup{
    width: 55%;
    margin: auto;
}

.clone-popup{
    width: 50%;
    margin: auto;
    .close-icon{
        .Polaris-Icon{
            height: 1.3rem;
        }
    }
    .popup-bg{
        border-radius: 5px;
    }
}
.delete-ppr-popup.clone-popup{
    width: 54%;
}
/*advanced search */
.advanced-search{
    max-width: 100%;
    margin: 0 auto;
    .button-wraper{
        border-top: 1px solid $aws-lightGrey;
    }
    .advanced-search-popup{
        .react-datepicker-wrapper{
            position: absolute;
        }
        .react-datepicker__input-container input{
            width: 90% !important;
        }
        .date-wraper .react-datepicker-popper {
            z-index: 33;
            width: 91%;
           
        }
        .date-wraper .calendar-icon .awsui-icon.awsui-icon-size-normal svg{
            top: 0;
        }
        .body-wraper{
            max-height: calc(100vh - 240px);
            overflow-y: auto;
        }
        .close-icon{
            .awsui-icon{
                top: 4px;
                position: relative;
            }
        }
        .date-wraper{
            float: left;
            width: 100%;
            margin-top: 5px;
            .Polaris-Icon{
                top: 5px;
            }
            .calendar-icon {
                top: 40px;
            }
            .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
                width: 7rem;
            }
            .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range{
                background-color: $aws-primaryColor;
            }
            .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
                padding: 3px;
            }
            .react-datepicker__header{
                padding-bottom: 8px;
            }
            .react-datepicker-year-header{
                font-size: 12px;
            }
        }
        
    }
}
.player-popup{
    background-color: $aws-white;
}
