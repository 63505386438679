@import '../../../styles/variables.scss';


.delete-icon{
  background-image: url('../../../images/delete_darkblack.svg');
}

.icons-styles{
  background-repeat: no-repeat;
  padding: 8px 6px;
  background-size: 12px;
  float: left;
  margin-left: 5px;
  margin-top: 4px;
  cursor: pointer;
}

.edit-icon{
  background-image: url('../../../images/edit_black.svg');
  background-size: 100% 100%;
  padding: 12px;
  margin-top: 0;
}