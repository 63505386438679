@import '../../../styles/variables.scss';
 
.btns{
 .awsui-button.awsui-button-variant-normal{
   min-width: 88px;
   border-radius: 4px;
   padding: 4px 12px;
   background: transparent !important;
   color: $aws-primaryColor;
   font-size: 14px;
   font-family: AmazonEmberRegular;
   border: .1rem solid $aws-primaryColor;
   background: transparent;
   text-align: center;
   font-weight: normal;

   .unfill-btn{
     .awsui-button.awsui-button-variant-normal{
       background: transparent !important; 
     }
     .awsui-button.awsui-button-variant-normal.awsui-button-disabled{
       color: $aws-grey !important;
       background: $aws-lightGrey !important;
       border: 1px solid $aws-lightGrey !important;
       cursor: pointer !important;
     }
   }
   
   img{
     width: 16px;
     position: relative;
   }
   .rightarrow{
     top: 0;
     margin-left: 6px;
   }
   .leftarrow{
     float: left;
     top: 6px;
     right: 3px;
     transform: rotate(180deg);
     margin-left: 0;
   }
 }
 
}
.fill-btn{
 .awsui-button.awsui-button-variant-normal{
   background: $aws-primaryColor !important;
   color: $aws-white !important;
 }
 .awsui-button.awsui-button-variant-normal:hover{
    background: $aws-primaryColor !important;
 }
 .awsui-button.awsui-button-variant-normal.awsui-button-disabled{
   color: $aws-grey !important;
   background: $aws-lightGrey !important;
   border: 1px solid $aws-lightGrey !important;
   cursor: pointer !important;
 }
}
