@import '../../../styles/variables.scss';
.AWS-input-div{
    .input-label{
        font-family: AmazonEmberRegular;
        font-size: 14px;
        color: $aws-darkBlack;
    }
    input{
        background: $aws-white 0% 0% no-repeat padding-box;
        border: 1px solid $aws-lightGrey;
        font-family: AmazonEmberRegular;
    } 
    .input-wraper{
        position: relative;
        float: left;
        width: 100%;
        .addresbook{
            position: absolute;
            right: 10px;
            z-index: 20;
            top: 8px;
            cursor: pointer;
        }
    }
    .error-icon{
        .anticon svg{
            position: relative;
            top: -3px;
            font-size: 14px;
            color: $aws-red;
        }
        
    }
}
.autosuggest-container{
 .label-txt{
     color: $aws-darkBlack;
     font-family: AmazonEmberRegular;
 }   
}