@import '../../../styles/variables.scss';
.switch-wraper{
    .toggle-txt{
        width: 20px;
        display: inline-block;
        text-align: right;
    }
    .awsui-toggle-native-input{
        cursor: pointer;
    }
    awsui-toggle > label.awsui-toggle-disabled.awsui-toggle-checked .awsui-toggle-styled-box{
        background: rgba(255, 165, 0, 0.2) !important;
    }
    .awsui-toggle-disabled{
        pointer-events: none;
    }
}
