@import '../../../styles/variables.scss';
.header-wraper{
    header{
    z-index: 23;
    max-width: 100%;
    background: #f2f2f2 !important;
    border-bottom: 1px solid $aws-lightGrey;
    position: absolute;
    width: 100%;
    padding: 5px 0;
    .logo{
        .logo-wraper{
            float: left;
            height: 64px;
            padding-left: 40px;
            overflow: hidden;
            line-height: 64px;
            white-space: nowrap;
            text-decoration: none;
            padding-top: 2px;
            .falcon-txt{
                position: relative;
                top: -1.8px;
            }
            .logo-img{
                height: 32px;
                position: relative;
                top: 16px;
            }
            .logo-title{
                color:$aws-darkBlack;
                font-family: AmazonEmberRegular;
                // font-weight: bold;
                font-size: 21px;
                padding: 5px 15px;
                position: relative;
                top: -1.7px;
            }
            .logo-sub-title{
                color:$aws-darkBlack;
                font-size: 18px;
            }
        }
        
    }
    .save-icon{
        img{
            height: 20.8px;
            position: relative;
            top: -.245px;
        }
    }
    .down-arrow{
        img{
            top: -0.3px;
            position: relative;
        }
    }
    .user-name-div{
        margin-top: 0;
    }
    .notification-user-details{
        padding-right: 35px;
        .user-icon{
            width: 23px;
            height: 22px;
        }
        .notification {
            cursor: pointer;
            img{
                width: 24px;
                height: 24px;
            }
            .notification-count{
                background: $aws-primaryColor;
                min-width: 15px;
                min-height: 15px;
                z-index: 9999;
                display: inline-block;
                position: relative;
                top: -8px;
                left:-12px;
                border-radius: 50%;
                color: $aws-white;
                font-size: 10px;
                text-align: center;
                line-height: 16px;
            }
        }
    }
    .home-icon{
        .user-icon{
            height: 21px;
        }
    }
    .black {
        color: black;
    }
  }
  .ticket-icon {
    height: 20px;
    width: 23px;
}

.wisdom-icon{
    height: 20px;
    // width: 23px;
}
   
.video-icon{
    width: 23px;
    height: 22px;
    margin-top: 0px
}
    .user-icon.home-icon{
        height: 20.8px;
        top: -.3px;
    }
   
}
.logo-hover{
    position: relative;
    .logo-tooltip{
        position: absolute;
        right: 10px;
        top: -12px;
        background-color: $aws-white;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        display: none;
        z-index: 1;
        width: max-content;
    }
    // .ticketing-tooltip{
    //     right: 155px;
    // }
    // .wisdom-tooltip{
    //     right: 188px;
    // }
    // .training-tooltip{
    //     right: 234px;
    // }
    // .save-tooltip{
    //     right: 291px !important;
    // }
    // .home-white-tooltip{
    //     right: 305px !important;
    // }
    // .admin-wisdom-tooltip{
    //     right: 128px !important;
    // }
    // .admin-ticketing-tooltip{
    //     right: 50px !important;
    // }
    // .save-white-tooltip{
    //     right: 262px !important;
    // }
}

.logo-hover:hover > .logo-tooltip{
    display: block;
}
