/*firefox */
@-moz-document url-prefix() {
    .additional-table{
        line-height: unset !important;
    } 
    .awsui-table-container{
        table{
            border-collapse: separate;
        }
    }
    select{
        font: 1.2em "AmazonEmberRegular", sans-serif;
    }
    select > option{
        font: 1.4rem "AmazonEmberRegular", sans-serif;
        }
    .summary-content{
        padding-right: 10px !important;
    }
    .moz-scroll{
        scrollbar-width: thin;
        scrollbar-color: $aws-lightGrey $aws-white;
        background-color: transparent;
    }
    .Polaris-TextField--multiline > .Polaris-TextField__Input,
    .admin-table .tabulator .tabulator-tableHolder,
    .table-wraper .tabulator .tabulator-tableHolder{
        scrollbar-width: thin;
        scrollbar-color: $aws-lightGrey $aws-white
    }
    .tabulator-tableHolder,
    .body-wraper{
        scrollbar-width: thin;
        scrollbar-color: $aws-lightGrey $aws-white
    }
    .customer-info-padding{
        scrollbar-width: none;
    }
    .aws-dash .timeframe-datepicker .totxt{
     left: 113px;
    }
    .quest-wraper{
        .checkbox-isvisble{
            max-width: 48% !important;
        }
        .ismandatory-checkbox{
            max-width: 52% !important;
        }
        
    }
    .main-container{
        top: 74px !important;
    }
    .summary-wraper .data-transfer-table.withouttier .width9p3 {
        width: 9.01%;
    }
    .withouttier .data-transfer tbody tr td,
    .withouttier .data-transfer thead tr th{
        width: 48.5px !important;
    }
    /*regional Usage*/
    .regional-usage-table .data-transfer tbody tr td,
    .regional-usage-table .data-transfer thead tr th{
    width: 54.5px !important;
    }
    .regional-traffic-table tr td.width9p3 {
        width: 54.8px !important;
    }
    .copy-table thead tr th.widthregionSummary{
        width: 54.8px !important;
    }

    .awsui-modal-dialog.awsui-modal-expandtofit{
        .awsui-form-field-label{
            float: unset !important;
        }
        .awsui-form-field-control{
            scrollbar-width: thin;
            scrollbar-color: $aws-lightGrey $aws-white
        }
    }
    @media only screen and (device-width: 1600px){
        .withtier .data-transfer tbody tr td, 
        .withtier .data-transfer thead tr th {
            width: 48.3px !important;
        }
        .withouttier .data-transfer tbody tr td,
        .withouttier .data-transfer thead tr th {
            width: 58.7px !important;
        }
        .regional-usage-table .data-transfer tbody tr td,
        .regional-usage-table .data-transfer thead tr th {
            width: 65.8px !important;
        }
        .copy-table thead tr th.widthregionSummary {
            width: 67.7px !important;
        }
        .regional-traffic-table tr td.width9p3 {
            width: 67.7px !important;
        }
    }
    @media only screen and (device-width: 1980px){
        .withtier .data-transfer tbody tr td, 
        .withtier .data-transfer thead tr th {
            width: 61.7px !important;
        }
        .withouttier .data-transfer tbody tr td, 
        .withouttier .data-transfer thead tr th {
            width: 75px !important;
        }
        .regional-usage-table .data-transfer tbody tr td,
        .regional-usage-table .data-transfer thead tr th {
            width: 83.8px !important;
        }
        .copy-table thead tr th.widthregionSummary {
            width: 87.7px !important;
        }
        .regional-traffic-table tr td.width9p3 {
            width: 87.7px !important;
        }
    }
}
@media only screen and (device-width: 1280px){

    .withouttier .data-transfer tbody tr td, .withouttier .data-transfer thead tr th {

        width: 45.2px !important;

    }

    .withtier .data-transfer tbody tr td, .withtier .data-transfer thead tr th {

        width: 37px !important;

    }

    .regional-usage-table .data-transfer tbody tr td, .regional-usage-table .data-transfer thead tr th {

        width: 50.8px !important;

    }

    .data-transfer thead tr th.widthregionSummary {

        width: 50.6px !important;

    }

    .regional-traffic-table tr td.width9p3 {

        width: 50.6px !important;

    }

}