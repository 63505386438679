@import '../../styles/variables.scss';
.aws-dash{
    .big-button{
        .btns{
            padding: 0;
            border-radius: 4px;
            float: right;
            margin-right: 0;
            img{
                width: 24px;
                margin-left: 14px;
            }
            .awsui-button.awsui-button-variant-normal .rightarrow{
                top: -1.4px;
            }
        }
    }
   
    .nav-container{
        position: relative;
        width: 100%;
    }

    .main-container{
        // height: calc(100vh - 45px);
        // position: fixed;
    }
    .table-grey-close-icon,
    .table-close-icon{
        .awsui-icon.awsui-icon-size-normal svg{
            height: 13px;
            top: 1px;
        }
        .awsui-icon svg *{
            stroke: $aws-white;
        }
    }
    .table-grey-close-icon{
        .awsui-icon svg *{
            stroke: #abaaaa;
        }
    }

    .tooltip-style{
        position: absolute; 
        top: 30px;
        background-color: #fff;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        display: none;
    }
    .advance-search-icon{
        .pagination-style{
            position: absolute;
            right: 0;
            top: 46px;
            z-index: 9;
        }

        // sales manager & ppo onwer
        .pagination-style.otheruser-pagination-style{
            top: 45px;
        }
        //sceowner & Private Pricing China & privte pricing Public
        .pagination-style.sceprivate-publicowner{
            top: 61px;
        }
        .advance-tooltip{
            right: 0;
        }
    }

    .search-icon-advance{          
        img{
            width: 20px;
            height: 20px;
        }
    }
   
    .pagination-wraper{
        .pagination-tooltip{
            left: 0;
            z-index: 8;
            width: auto;
            right: unset;
            top: 35px;
        }
    }
    .setting-wraper{
        .setting-tooltip{
            right: 0;
            top: 32px;
        }
    }
    .dropdown-youtube{
        float: left;
        .dropdown-youtube-tooltip{
            top: 1px;
            right: 160px;
        }
    }
    .dropdown-youtube:hover > .dropdown-youtube-tooltip{
        display: block;
    }
    .setting-wraper:hover > .setting-tooltip{
        display: block;
    }
    .pagination-wraper:hover > .pagination-tooltip{
        display: block;
    }
    .advance-search-icon:hover > .advance-tooltip{
        display: block;
    }
    
   
    .controls-wraper{
        float: right;
        width: auto;
        padding: 0;
        position: relative;
        top: 18px;
        z-index: 9;
        .border-box{
            border: 1px solid $aws-lightGrey;
            border-radius: 6px;
            padding: 4px 5px;
        }
        .down-icon.border-box{
            padding: 3px 6px;
            .Polaris-Icon{
                height: 1.9rem;
                width: 1.8rem;
                .Polaris-Icon__Svg{
                    fill: $aws-white;
                }

            }
        }
        .input-search.border{
            border: none !important;
        }
    }
    /*time frome calendar */
    .timeframe-datepicker{
        width: 225px;
        .totxt{
            color: $aws-lightGrey;
            position: absolute;
        }
        .date-wraper {
            position: relative;
            .react-datepicker__navigation--previous{
                left: 14px;
                top: 13px;
            }
            .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
                margin-top: -6px;
            }
            .react-datepicker-popper{
                width: 160px;
                .react-datepicker__month-container{
                    .react-datepicker__header{
                        .react-datepicker__current-month{
                            font-size: 12px;
                        }
                        .react-datepicker__day-names{
                            .react-datepicker__day-name{
                                width: 1.7rem;
                            }
                        }
                    }
                    .react-datepicker__day{
                        font-size: 10px;
                        line-height: 1.5rem;
                        width: 1.7rem;
                    }
                    .react-datepicker__day--keyboard-selected{
                        background-color: $aws-primaryColor;
                    }
                    .react-datepicker__day--selected{
                        background-color: $aws-primaryColor;
                    }
                }
            }
            .react-datepicker-wrapper {
                .react-datepicker__input-container input{
                    background-color: transparent;
                    border: none;
                    color: $aws-lightGrey;
                    padding: 0;
                }
            }
        }
        .start-date{
            .react-datepicker-wrapper {
                .react-datepicker__input-container input{
                    text-align: right;
                }
            }
            .react-datepicker-popper{
                left: -60px !important;
            }
        }
        .end-date{
            .react-datepicker-popper {
                left: -70px !important;
            }
        }
    }
    .setting-youtube{
        position: absolute;
        right: 0;
        top: 58px;
        z-index: 9;
        right: 70px;
        .video-icon{
            // height: 11.4px;
            position: relative;
            top: -1px;
            height: 14px;
        }
    }
    .pagination-youtube.youtube-icon .awsui-icon.awsui-icon-size-normal ,
    .setting-youtube.youtube-icon .awsui-icon.awsui-icon-size-normal {
        margin-top: 0;
    }
    .pagination-youtube{
        top: 60px;
        position: absolute;
        z-index: 7;
        .video-icon{
            // height: 11.4px;
            width: auto;
            height: 14px;
        }
    }
    /*dashboard drop down */
    .dashboard-dropdown{
        .video-icon{
            // height: 11.4px;
            width: auto;
            position: relative;
            top: 0;
            height: 14px;
        }
       
        .drop-wraper {
            .disinline{
                display: none;
            }
            .awsui-select-dropdown{
                width: 130px !important;
                .awsui-select-option-label{
                    font-size: 13px !important;
                }
            }
            .awsui-select-trigger{
                background-color: transparent;
                color: $aws-lightGrey;
                border: none;
            }
            .awsui-select-trigger-textbox{
                display: block ;
                .awsui-select-trigger-label{
                    float : right ;
                    position: relative;
                    top: -1px;
                }
            }
            .awsui-select-trigger-icon{
                padding-right: 0 !important;
            }
           
        }
    }
    .bg-gradient{
        background: transparent linear-gradient(293deg, #1D2837 0%, #324155 100%) 0% 0% no-repeat padding-box;
    }
    .page-wrapper{
        height: 100%;
        width: 100%;
        padding: 0;
    }
    .padding-left5p{
        padding-left: 5.1rem;
    }
    .padding-left2p{
        padding-left: 1.5rem;
    }
    .tabs-contanier{
        ul{
            width: auto;
        }
        li{
            display: inline-block;
            cursor: pointer;
        }
        .active-tab{
            border-bottom: 0.3rem solid $aws-primaryColor;
        }
     
        .tablist{
            width: max-content;
        }
      
        .icons-styles{
            background-repeat: no-repeat;
            padding: 8px 6px;
            background-size: 12px;
            float: left;
            margin-left: 5px;
            margin-top: 4px;
        }
        .delete-icon{
            background-image: url('../../images/delete.svg');
        }
        .delete-icon-grey{
            // background-color: grey;
            background-image: url('../../images/delete_black.svg');
        }
        .clone-icon{
            background-image: url('../../images/clone_white.svg');
            background-size: 16px;
            margin-left: 0;
            padding: 10px;
            margin-right: 3px;
        }
        .clone-grey-icon{
            background-image: url('../../images/clone_grey.svg');
            background-size: 16px;
            padding: 8px 10px;
            margin-left: 0;
            margin-right: 3px;
        }
        .more-icon{
            background-image: url('../../images/More_Icon.svg');
            background-size: 22px;
            margin-left: 0;
            margin-top: 10px;
            margin-right: 1px;
        }
    }
    .submitted-table{
      position: absolute;
      top: 42px;
    }
    
}

.status-circle{
  height: 12px;
  width: 12px;
  border-radius: 10px;
  margin: 0 auto;
  display: block;
  top: 0;
  position: relative;
}

.tabs-contanier {
    ul{
        position: relative;
        z-index: 8;
    }
    .pagination-style{
        ul{
            right: 24px;
            min-width: auto;
            border:none;
            padding-left: 1rem;
            border-radius: 5px;
            li {
                padding: 2px 10px;
                margin-right: 4px;
                border: 1px solid $aws-tableBorderColor;
                border-radius: 3px;
                img{
                    width: 6px;
                    position: relative;
                    top: -1px;
                }
                color:$aws-lightGrey;
            }
        }
        .search-icon{                
            padding: 2.7px 6px;
            margin-right: 0;
            border: .5px solid $aws-tableBorderColor;
            border-radius: 3px;
            img{
                // width: 21px;
                width: 14px;
                position: relative;
                top: -1px;
            }
        }
    }
}
.anchortag {
    text-decoration: underline !important;
}
.tabs-contanier {
    .pagination-style{
        .column-action-icon{
            right: 0px;
            top: 10px;
            margin-right: 0;
            padding-right: 0;
           
            li{
                padding: 4px 6px 4px 5px;
            }
        }
        .column-action-icon:focus{
            outline: none;
        }
    }
}
.errorPagePadding{	
    padding-top: 6%;	
}
.margin-allppr{
    margin-top: 25px;
}
.awsui-checkbox-label{
    color: $aws-primaryColor;
    font-size: 14px;
    font-family: AmazonEmberRegular;
}
  /*media query for mobiles*/
  @media only screen and (min-width: 576px) and (max-width: 767px){
    .aws-dash{
        .logo-title{
            font-size: 18px;
        }
        .card-wraper div{
            width: 100%;
            margin-bottom: 10px;
        }
        .controls-wraper{
            top: 0;
        }
    }
    
}
