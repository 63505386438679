.quest-wraper{
    .quest-dropdown{
        .drop-wraper{
            width: 100%;
        }
    }
    .validation-group{
         border: 1px solid #e5e5e5;
         width: 96%;
         height: 248px;
         overflow-y: auto;
         .Polaris-Stack > .Polaris-Stack__Item{
            width: 98%;
            float: left;
         }
    }
    .checkbox-hover{
        position: relative;
        .tool-tip{
            display: none;
        }
    }
    .checkbox-hover:hover .tool-tip{
        display: block;
        position: absolute;
        left: 0;
        width: 60px;
        z-index: 9;
        top: -10px;
    }
}
