@import '../../../styles/variables.scss';
.date-wraper{
    .label-txt{
        margin-bottom: .5rem;
    }
    .date-label-txt{
        font-family: AmazonEmberRegular;
    }
    .react-datepicker-wrapper{
        width: 100%;
        .react-datepicker__input-container{
            outline: none;
            input{
                border-radius: 4px;
                box-shadow: none;
                padding: 0.7rem 1.2rem;
                background-color: $aws-white;
                border: .5px solid $aws-lightGrey;
                width: 100%;
                outline: none;
                color: $aws-darkBlack;
                font-family: AmazonEmberRegular;
            }
            .react-datepicker-ignore-onclickoutside:focus{
                outline: none;
                border: .5px solid $aws-primaryColor;
            }
        }
        
    }
    input[type="text"]:disabled {
        background-color: #eaeded;
        border: none;
        color: #919eab;
    }
    input[type="text"]::placeholder {
        font-style: italic;
        color: #aab7b8;
    }
    
    .react-datepicker-popper{
        z-index: 29;
        left: 8px;
        width: 100%;
        .react-datepicker{
            font-family: AmazonEmberRegular;
            font-size: 12px;
            background-color: $aws-white;
            color: $aws-darkBlack;
            border: .5px solid $aws-lightGrey;
            border-radius: 4px;
            width: 100%;
            box-shadow: inset 0 2px 2px #e9e9e9, 0 0 10px 0 rgba(73,107,125,.3);
            .react-datepicker__navigation{
                border-width: .7rem;
            }
            .react-datepicker__month-container{
                width: 100%;
                right: 0;
                .react-datepicker__header{
                    border-bottom: none;
                    .react-datepicker__current-month{
                        font-size: 14px;
                        color: $aws-darkBlack;
                    }
                    .react-datepicker__day-names{
                        padding: 5px 0;
                        .react-datepicker__day-name{
                            width: 2.7rem;
                        }
                    }
                }
                .react-datepicker__day{
                    font-size: 12px;
                    line-height: 2.5rem;
                    width: 2.7rem;
                }
                .react-datepicker__day--keyboard-selected{
                    background-color: $aws-primaryColor;
                }
                .react-datepicker__day--selected{
                    background-color: $aws-primaryColor;
                }
            }
            .react-datepicker__triangle{
                right: 8px;
                left: auto;
            }
            
           
        }
        
    }
    
    .calendar-icon{
        position: absolute;
        right: 10px;
        top: 33px;
        cursor: pointer;
        .awsui-icon.awsui-icon-size-normal svg{
            height: 20px;
            top: -3px;
        }
        .awsui-icon svg *{
            stroke: #707070;
        }
    }
    .calendar-icon.disable-calendar{
        cursor: auto;
        .awsui-icon svg * {
            stroke: #b9c3ce;
        }
    }
}
