@import '../../styles/variables.scss';

.logout-wrapper{
  width: max-content;
  position: absolute;
  top: 47px;
  right: 10px;
    ul{
        height: auto;
        margin-bottom: 0;
        a{
          color: $aws-grey;
        }
        li{
            padding: 3px 30px;
        }
    }
    .ticketing{
      border-top: 1px solid $aws-lightGrey;
    }
}
.logout-grey{
  background-color: #f2f2f2;
}

.logout-white{
  background-color: $aws-white;
}
.logout-white{
  li:hover{
    background-color: $aws-primaryColor;

    color: $aws-white !important;
  }
}