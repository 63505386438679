@import '../../../styles/variables.scss';
.aos-wraper{
    li{
        display: inline-block;
        input{
            border-radius: 0;
        }
    }
    .title-txt{
        .title-wraper{
            border: 1px solid $aws-darkBlack;
            background: #cecece39 0% 0% no-repeat padding-box;
            width: 9.1%;
            float: left;
            display: table;
            height: 42px;
            margin-right: 4px;
            span{
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                color: $aws-tableBorderColor;
            }
        }
        .title-wraper:first-child{
            width: 14.4%;
        }
        .title-wraper:last-child{
            margin-right: 0;
            width: 9.3%;
            float: right;
        }
    }
    .sub-input{
        width: 9.05%;
        float: left;
        margin-right: 3.9px;
        input{
            border: 1px solid $aws-lightGrey;
            border-radius: 0;
            color: $aws-grey;
            font-size: 12px;
            text-align: center;
            min-height: 2.6rem;
            padding: 0;
        }
        input[type="text"]::placeholder{
            color: $aws-grey;
            font-size: 12px;
            font-family: 'AmazonEmberRegular';
        }
    }
    .sub-input:first-child{
        width: 14.35%;
    }
    .sub-input:last-child{
        margin-right: 0;
        width: 9.3%;
        float: right;
    }
    .subinput-first{
        width: 14.35%;
    }
}
/*global limit popup */
.global-limit-popup{
    padding: 0;
    .tabulator-tableHolder{
        .tabulator-cell:first-child{
            padding-left: 45px;
            text-align: left;
        }
    }
    .tabulator-tableHolder::-webkit-scrollbar{
        width: 5px;
      } 
    .tabulator-col-resize-handle{
        display: none
    }
    .tabulator-col-title{
        font-size: 12px;
    }
}
/*request fee table */
.request-admin-wraper{
    .aos-wraper {
        .title-txt {
            .title-wraper{
                width: 8.68%;
            }
            .title-wraper:last-child{
                width: 9.2%;
            }
        }
        .sub-input:first-child{
            width: 8.5%;
        }
        .sub-input{
            width: 8.66%;
        }
        .sub-input:last-child{
            width: 9.1%;
        }
    }
}
/*tabs*/
.data-transfer{
    .tabs-button-wraper {
        li{
            width: 16%;
            border-radius: 0;
            border-right: 1px solid $aws-lightGrey;
        }
        li.active:last-child,
        li:last-child,
        li:first-child{
            border-radius: 0;
        }
    }
}

.tabs-button-wraper.aosButtonWidth{
    ul{
        margin-bottom: 0;
        li{
            width: 21%;
        }
    }
}

/*cloud tabs*/
.cloud-tab{
    .tabs-button-wraper{
        ul{
            margin-bottom: 2px;
            li{
                margin-right: 1px;
                border-radius: 0;
                border-right: 1px solid $aws-lightGrey;
            }
            li.active:last-child,
            li:last-child,
            li:first-child{
                border-radius: 0;
            }
        }
    }
    .rate-cardtabs{
        li{
            width: auto;
        }
    }
    .request-tab{
        .tabs-button-wraper{
            li{
                width: 13%;
                border-radius: 0;
                margin-right: 1px;
                border-right: 1px solid $aws-lightGrey;
            }
            li.active:last-child,
            li:last-child,
            li:first-child{
                border-radius: 0;
            }
        }
    }
    .data-transfer{
        .tabs-button-wraper {
            li{
                margin-right: 1px;
            }
        }
    }
    .sub-tabs-wraper{
        border-bottom: 1px solid $aws-grey;
        height: 72px;
    }
}
.data-transfer-dropdown{
    position: relative;
    top: -23px;
}
/*media query */
@media (min-width: 1920px){
    .aos-wraper {
        .title-txt {
            .title-wraper{
                width: 9.2%;
            }
            .title-wraper:last-child{
                width: 9.7%;
            }
        }
        .sub-input{
            width: 9.2%;
        }
    }
    .request-admin-wraper {
        .aos-wraper {
            .title-txt {
                .title-wraper{
                    width: 8.8% ;
                }
                .title-wraper:last-child{
                    width: 9%;
                }
            }
            .sub-input{
                width: 8.81%;
            }
        }
        
        
    }
}
.tabulator{
    background: $aws-white;
}
