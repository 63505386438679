@import '../../../styles/variables.scss';

// [aria-hidden="true"] {
//     opacity: 0;
//     position: absolute;
//     z-index: -9999;
//     pointer-events: none;
//   }

.summary-wraper{
    // overflow-y: auto;
    // max-height: calc(100vh - 210px);
    // height: auto;
    padding-top: 16px;
    // added padding-left
    .urlpath{
        opacity: 0;
        z-index: -9999;
        pointer-events: none;
        position: absolute;
    }
    .summary-content{
        overflow-y: auto;
        max-height: calc(100vh - 390px);
        // max-height: 330px;
        height: auto;
        overflow-x: hidden;
        .pricing-txt{
            line-height: 1.4;
        }
    }

    // summary data key value's css
    .sub-title {
        font-family: AmazonEmberBold;
    }
    // summary data key value's css
    .payer-ids-affiliate {
        width: 60%;
        overflow-wrap: break-word;
    }

    // summary data key value's css
    .payer-ids {
        width: 80%;
        overflow-wrap: break-word;
    }

    // disabling text selection while cpoying from summary page
    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently */
    }
    .copy-pts-wraper{
        position: absolute;
        right: 0;
        top: -30px;
    }
    .generate-ptsicon{
        img{
            width: 12px;
        }
    }
    .comment-wraper{
        .pricing-textarea{
            word-break: break-word;
        }
        .additional-table{
            li{
                display: inline-block;
                border: 1px solid #747474;
                border-bottom: none;
                border-right: none;
            }
            li:last-child{
                border-right: 1px solid #747474;
            }
        }
        .additional-tabledata{
            .display-flex:nth-child(even) > li{ 
                background-color: hsla(0,0%,100%,.1);
            }
            .display-flex:last-child >li{
                border-bottom: 1px solid #747474;
            }
            li{
                display: inline-block;
                border: 1px solid #747474;
                border-bottom: none;
                border-right: none;
            }
            li:last-child{
                border-right: 1px solid #747474;
            }
        }
        // border-top: 1px solid $aws-grey;
    }
    // border-left: 1px solid $aws-white;
    .Polaris-Label__Text{
        color: $aws-lightGrey;
        font-size: 12px;
    }
    .Polaris-TextField--multiline > .Polaris-TextField__Input{
        background: transparent;
        height: 70px !important;
        padding: 3px 10px;
        color: $aws-white;
    }
    .Polaris-TextField__Backdrop{
        background-color: transparent;
        border-radius: var(--p-border-radius-base, 4px);
    }
    .tite-wraper{ 
        position: relative;
        // top: -30px;
    }
    .summary-left{
        border-right: none;
    }
    textarea{
        border: 1px solid $aws-lightGrey;
        border-radius: 6px;
        color: $aws-white;
        background-color: transparent;
        resize: none;
    }
    textarea::-webkit-input-placeholder{
        font-style: italic;
    }
    .padding-summary-from-title{
        position: relative;
        // top: -25px;
    }

    .editable-table .ant-table-thead > tr > th{
        color: $aws-lightGrey !important;
        font-size: 12px !important;
        background: #CECECE39 0% 0% no-repeat padding-box !important;
        border: 1px solid #505C6B;
        padding: 2px 4px !important;
    }
    .editable-table .ant-table-tbody > tr > td{
        background: #CECECE 0% 0% no-repeat padding-box !important;
        border: 1px solid #D9D5D5;
        color: #747474 !important;
        font-size: 10px !important;
        padding: 2px 1px !important;
    }
    .editable-table .ant-table-tbody > tr > td:first-child span{
        width: auto !important;
    }
    .summary-table{
        // .editable-table{
        //     overflow-x: auto;
        // }
        .widthregional{
            .width9p3:nth-child(2),
            .width9p3:first-child{
                width: 12%;
            }
        }
        .width9p3{
            width: 7.3%;
            text-align: center;
            .grey-txt{
                color: $aws-lightGrey;
                font-size: 10px;
            }
            .Polaris-TextField__Input{
                background: $aws-lightGrey 0 0 no-repeat padding-box!important;
                padding: 2px 0;
                line-height: 1;
                min-height: auto;
                border-radius: 0;
                font-size: 10px;
                text-align: center;
                color: $aws-grey;
            }
        }
        .table-border.width9p3{
            background: hsla(0,0%,80.8%,.22) 0 0 no-repeat padding-box;
            // border: 1px solid $aws-darkBlack;
        }
        ::-webkit-scrollbar{
            height: 2px;
        }
        ::-webkit-scrollbar-thumb{
            background: $aws-primaryColor;
        }
        .widthregionSummary {
            display: table;
            height: 44px;
            width: 7.4%;
            .width9p3{
                width: 7.03%;
            }
            .grey-txt{
                display: table-cell;
                vertical-align: middle;
            }
        }
        
    }
    .btns .awsui-button.awsui-button-variant-normal.awsui-button-disabled{
        color: $aws-grey !important;
        background: $aws-lightGrey !important;
        border: 1px solid $aws-lightGrey !important;
        cursor: pointer !important;
    }
       
    .copy-youtube-icon{
        img{
            // height: 11.4px;
            margin-right: 6px;
        }
    }
    .summary-left-text{
        word-break: break-word;
    }
    .btmbtn-group{
        padding-right: 5px;
    }
    .btmbtn-group:last-child{
        padding-right: 0;
    }
    .comment-box{
        border-top: .5px solid $aws-grey;
        .AWStextarea-div textarea{
            background-color: transparent;
        }
        .awsui-textarea{
            color: $aws-white !important;
        }
    }
    .data-transfer-table.withouttier{
        .width9p3 {
            width: 9.09%;
        }
        .width9p3:last-child{
            margin-right: 0 !important;
        }
    }
    .data-transfer-table.withtier{
        .width9p3 {
            width: 7.4%;
        }
        .width9p3:last-child{
            margin-right: 0 !important;
        }
    }
    .regional-usage-table{
        .width9p3 {
            width: 10.2%;
        }
        .width9p3:last-child{
            margin-right: 0 !important;
        }
    }
    .regional-traffic-table{
        .widthregional .width9p3:nth-child(2),
        .widthregional .width9p3:first-child {
            width: 12.3%;
        }
        .widthregionSummary:first-child .grey-txt{
            padding-top: 0;
        }
        .widthregionSummary .grey-txt{
            display: block;
            padding-top: 12px;
        }
        
        .width9p3 {
            width: 9.6%;
        }
        .width9p3:last-child{
            margin-right: 0 !important;
        }
        .widthregionSummary{
            display: table-caption;
        }
    }
}
.nav-wrapper{
    .close-arrow-dot-margin{
        padding-right: 28px;
        margin-top: 10px;
    }
}
.nav-wrapper-expand.nav-wrapper{
    .close-arrow-dot-margin{
        padding-right: 0;
    }
}
.commentTextArea{
    color: $aws-lightGrey !important;
}
.comment-timestamp{
    color: $aws-lightGrey !important;
    font-size: 11px;
    padding-left: 2%;
}

.leftNav-copy-youtube{
    position: relative;
    margin-right: .7rem;
    .leftNav-copy-tooltip{
        position: absolute;
        left: 0;
        top: -23px;
        background-color: #fff;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        display: none;
        z-index: 1;
        width: max-content;
    }

    top: 1.8px;
    //position: absolute;
    z-index: 7;
    .video-icon{
        height: 15px;
    }
}

.leftNav-copy-youtube:hover > .leftNav-copy-tooltip{
    display: block;
}
/* media query for lower desktop*/

@media (min-width: 1200px){
    .summary-wraper {
        position: relative;
        top: 0;
        padding-top: 6px;
        .nav-txt{
            font-size: 11px;
        }
        .title-txt{
            font-size: 16px;
        }
        .tite-wraper{
            position: relative;
            top: 0;
        }
        .padding-summary-from-title{
            top: 0;
        }
        .editable-table .ant-table-tbody > tr > td{
            font-size: 9px !important;
        }
        .editable-table .ant-table-thead > tr > th{
            font-size: 11px !important;
        }
        // margin-right: 10px;
        .close-arrow-dot-margin{
            float: right;
            position: relative;
            top: 0;
            .summary-txt{
                top: -6px;
            }
        }
        
    }
}

@media (min-width: 1366px){
    .summary-wraper {
        // position: relative;
        // top: -11px;
        // overflow-y: auto;
        // max-height: calc(100vh - 290px);
        // height: auto;
        padding-top: 16px;
        .tite-wraper{
            position: relative;
            // top: -5px;
            // padding-bottom: 10px
        }
        .close-arrow-dot-margin{
            float: right;
            position: relative;
            top: -20px;
            .summary-txt{
                top: -10px;
            }
        }
       
    }
}
.separate{
    border-collapse: collapse;
}
.additional-table{
    table{
        width: 50%;
    }
    thead{
    th{
        // display: inline-block;
        border: 1px solid #747474;
        border-bottom: none;
        border-right: none;
        color: #cecece;
        font-weight: normal;
        width: 25%;
        padding: 1%;
        font-size: 12px;
        &:last-child{
            border-right: 1px solid #747474;
        }
    }
}
tbody{
    td{
        // display: inline-block;
        border: 1px solid #747474;
        border-bottom: none;
        border-right: none;
        color: #cecece;
        width: 25%;
        padding: 1%;
        font-weight: normal;
        font-size: 12px;
        word-break: break-all;
        &:last-child{
            border-right: 1px solid #747474;
            // border-bottom: 1px solid #747474;
        }
    }
 
    tr{
        &:last-child{
 
            td{
                border-bottom: 1px solid #747474;  
            }
        }
    }
}
}
.spcialized-table.additional-table{
    table{
        width: 90%;
        tbody > td,
        thead > th{
            width: 50%;
        }
    }
}
.additional-table{
    .after-copy-table {
        thead {
            th{
                color:#000 !important;
               
            }            
                    
        }
        td{
            color:#000 !important;
        } 
    }
}

.font-change{
    font-family: AmazonEmberBold !important;@import '../../../styles/variables.scss';

    // [aria-hidden="true"] {
    //     opacity: 0;
    //     position: absolute;
    //     z-index: -9999;
    //     pointer-events: none;
    //   }
    
    .summary-wraper{
        // overflow-y: auto;
        // max-height: calc(100vh - 210px);
        // height: auto;
        padding-top: 16px;
        // added padding-left
        .urlpath{
            opacity: 0;
            z-index: -9999;
            pointer-events: none;
            position: absolute;
        }
        .summary-content{
            overflow-y: auto;
            max-height: calc(100vh - 390px);
            // max-height: 330px;
            height: auto;
            overflow-x: hidden;
            .pricing-txt{
                line-height: 1.4;
            }
        }
    
        // summary data key value's css
        .sub-title {
            font-family: AmazonEmberBold;
        }
    
        // disabling text selection while cpoying from summary page
        .noselect {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
        }
        .copy-pts-wraper{
            position: absolute;
            right: 0;
            top: -34px;
        }
        .generate-ptsicon{
            img{
                width: 12px;
            }
        }
        .comment-wraper{
            .pricing-textarea{
                word-break: break-word;
            }
            .additional-table{
                li{
                    display: inline-block;
                    border: 1px solid #747474;
                    border-bottom: none;
                    border-right: none;
                }
                li:last-child{
                    border-right: 1px solid #747474;
                }
            }
            .additional-tabledata{
                .display-flex:nth-child(even) > li{ 
                    background-color: hsla(0,0%,100%,.1);
                }
                .display-flex:last-child >li{
                    border-bottom: 1px solid #747474;
                }
                li{
                    display: inline-block;
                    border: 1px solid #747474;
                    border-bottom: none;
                    border-right: none;
                }
                li:last-child{
                    border-right: 1px solid #747474;
                }
            }
            // border-top: 1px solid $aws-grey;
        }
        // border-left: 1px solid $aws-white;
        .Polaris-Label__Text{
            color: $aws-lightGrey;
            font-size: 12px;
        }
        .Polaris-TextField--multiline > .Polaris-TextField__Input{
            background: transparent;
            height: 70px !important;
            padding: 3px 10px;
            color: $aws-white;
        }
        .Polaris-TextField__Backdrop{
            background-color: transparent;
            border-radius: var(--p-border-radius-base, 4px);
        }
        .tite-wraper{ 
            position: relative;
            // top: -30px;
        }
        .summary-left{
            border-right: none;
        }
        textarea{
            border: 1px solid $aws-lightGrey;
            border-radius: 6px;
            color: $aws-white;
            background-color: transparent;
            resize: none;
        }
        textarea::-webkit-input-placeholder{
            font-style: italic;
        }
        .padding-summary-from-title{
            position: relative;
            // top: -25px;
        }
    
        .editable-table .ant-table-thead > tr > th{
            color: $aws-lightGrey !important;
            font-size: 12px !important;
            background: #CECECE39 0% 0% no-repeat padding-box !important;
            border: 1px solid #505C6B;
            padding: 2px 4px !important;
        }
        .editable-table .ant-table-tbody > tr > td{
            background: #CECECE 0% 0% no-repeat padding-box !important;
            border: 1px solid #D9D5D5;
            color: #747474 !important;
            font-size: 10px !important;
            padding: 2px 1px !important;
        }
        .editable-table .ant-table-tbody > tr > td:first-child span{
            width: auto !important;
        }
        .summary-table{
            // .editable-table{
            //     overflow-x: auto;
            // }
            p{
                line-height: 32px;
            }
            .widthregional{
                .width9p3:nth-child(2),
                .width9p3:first-child{
                    width: 12%;
                }
            }
            .width9p3{
                width: 7.3%;
                text-align: center;
                .grey-txt{
                    color: $aws-lightGrey;
                    font-size: 10px;
                }
                .Polaris-TextField__Input{
                    background: $aws-lightGrey 0 0 no-repeat padding-box!important;
                    padding: 2px 0;
                    line-height: 1;
                    min-height: auto;
                    border-radius: 0;
                    font-size: 10px;
                    text-align: center;
                    color: $aws-grey;
                }
            }
            .table-border.width9p3{
                background: hsla(0,0%,80.8%,.22) 0 0 no-repeat padding-box;
                // border: 1px solid $aws-darkBlack;
            }
            ::-webkit-scrollbar{
                height: 2px;
            }
            ::-webkit-scrollbar-thumb{
                background: $aws-primaryColor;
            }
            .widthregionSummary {
                display: table;
                height: 44px;
                width: 7.4%;
                .width9p3{
                    width: 7.03%;
                }
                .grey-txt{
                    display: table-cell;
                    vertical-align: middle;
                }
            }
            
        }
        .btns .awsui-button.awsui-button-variant-normal.awsui-button-disabled{
            color: $aws-grey !important;
            background: $aws-lightGrey !important;
            border: 1px solid $aws-lightGrey !important;
            cursor: pointer !important;
        }
           
        .copy-youtube-icon{
            img{
                // height: 11.4px;
                margin-right: 6px;
            }
        }
        .summary-left-text{
            word-break: break-word;
        }
        .btmbtn-group{
            padding-right: 5px;
        }
        .btmbtn-group:last-child{
            padding-right: 0;
        }
        .comment-box{
            border-top: 1px solid $aws-grey;
            .AWStextarea-div textarea{
                background-color: transparent;
            }
            .awsui-textarea{
                color: $aws-white !important;
            }
        }
        .data-transfer-table.withouttier{
            .width9p3 {
                width: 9.09%;
            }
            .width9p3:last-child{
                margin-right: 0 !important;
            }
        }
        .data-transfer-table.withtier{
            .width9p3 {
                width: 7.4%;
            }
            .width9p3:last-child{
                margin-right: 0 !important;
            }
        }
        .regional-usage-table{
            .width9p3 {
                width: 10.2%;
            }
            .width9p3:last-child{
                margin-right: 0 !important;
            }
        }
        .regional-traffic-table{
            .widthregional .width9p3:nth-child(2),
            .widthregional .width9p3:first-child {
                width: 12.3%;
            }
            .widthregionSummary:first-child .grey-txt{
                padding-top: 0;
            }
            .widthregionSummary .grey-txt{
                display: block;
                padding-top: 12px;
            }
            
            .width9p3 {
                width: 9.6%;
            }
            .width9p3:last-child{
                margin-right: 0 !important;
            }
            .widthregionSummary{
                display: table-caption;
            }
        }
    }
    .nav-wrapper{
        .close-arrow-dot-margin{
            padding-right: 28px;
            margin-top: 10px;
        }
    }
    .nav-wrapper-expand.nav-wrapper{
        .close-arrow-dot-margin{
            padding-right: 0;
        }
    }
    .commentTextArea{
        color: $aws-lightGrey !important;
    }
    .comment-timestamp{
        color: $aws-lightGrey !important;
        font-size: 11px;
        padding-left: 2%;
    }
    
    .leftNav-copy-youtube{
        position: relative;
        margin-right: .7rem;
        .leftNav-copy-tooltip{
            position: absolute;
            left: 0;
            top: -23px;
            background-color: #fff;
            padding: 1px 6px;
            border-radius: 4px;
            font-size: 12px;
            display: none;
            z-index: 1;
            width: max-content;
        }
    
        top: 1.8px;
        //position: absolute;
        z-index: 7;
        .video-icon{
            height: 15px;
        }
    }
    
    .leftNav-copy-youtube:hover > .leftNav-copy-tooltip{
        display: block;
    }
    /* media query for lower desktop*/
    
    @media (min-width: 1200px){
        .summary-wraper {
            position: relative;
            top: 0;
            padding-top: 6px;
            .nav-txt{
                font-size: 11px;
            }
            .title-txt{
                font-size: 16px;
            }
            .tite-wraper{
                position: relative;
                top: 0;
            }
            .padding-summary-from-title{
                top: 0;
            }
            .editable-table .ant-table-tbody > tr > td{
                font-size: 9px !important;
            }
            .editable-table .ant-table-thead > tr > th{
                font-size: 11px !important;
            }
            // margin-right: 10px;
            .close-arrow-dot-margin{
                float: right;
                position: relative;
                top: 0;
                .summary-txt{
                    top: -6px;
                }
            }
            
        }
    }
    
    @media (min-width: 1366px){
        .summary-wraper {
            // position: relative;
            // top: -11px;
            // overflow-y: auto;
            // max-height: calc(100vh - 290px);
            // height: auto;
            padding-top: 16px;
            .tite-wraper{
                position: relative;
                // top: -5px;
                // padding-bottom: 10px
            }
            .close-arrow-dot-margin{
                float: right;
                position: relative;
                top: -20px;
                .summary-txt{
                    top: -10px;
                }
            }
           
        }
    }
    .separate{
        border-collapse: collapse;
    }
    .additional-table{
        table{
            width: 50%;
        }
        thead{
        th{
            // display: inline-block;
            border: 1px solid #747474;
            border-bottom: none;
            border-right: none;
            color: #cecece;
            font-weight: normal;
            width: 25%;
            padding: 1%;
            font-size: 12px;
            &:last-child{
                border-right: 1px solid #747474;
            }
        }
    }
    tbody{
        td{
            // display: inline-block;
            border: 1px solid #747474;
            border-bottom: none;
            border-right: none;
            color: #cecece;
            width: 25%;
            padding: 1%;
            font-weight: normal;
            font-size: 12px;
            word-break: break-all;
            &:last-child{
                border-right: 1px solid #747474;
                // border-bottom: 1px solid #747474;
            }
        }
     
        tr{
            &:last-child{
     
                td{
                    border-bottom: 1px solid #747474;  
                }
            }
        }
    }
    }
    .spcialized-table.additional-table{
        table{
            width: 90%;
            tbody > td,
            thead > th{
                width: 50%;
            }
        }
    }
    .additional-table{
        .after-copy-table {
            thead {
                th{
                    color:#000 !important;
                   
                }            
                        
            }
            td{
                color:#000 !important;
            } 
        }
    }
    
    .font-change{
        font-family: AmazonEmberBold !important;
    }
    
}
