@import '../../../styles/variables.scss';
.drop-wraper{
    position: relative;
    label{
        color: $aws-darkBlack;
        font-size: 14px;
        margin-bottom: 0 ;
    }
    select{
        padding: 10px;
        font-family: AmazonEmberRegular;
        font: normal 14px 'AmazonEmberRegular';
        border-width: .5px;
    }
    
    .disblock{
        display: block;
    }
    .disinline{
        display: inline-block;
    }
   .awsui-select-trigger{
       border: .5px solid $aws-lightGrey;
       border-radius: 4px !important;
       height: 3rem !important;
   }
   .awsui-select-trigger.awsui-select-trigger-variant-labe{
        padding: .5rem 0 .4rem 1rem !important;
   }
   .awsui-select-dropdown-option{
       border: none !important;
   }
   .awsui-select-dropdown-option-selected{
        border-color: transparent !important;
   }
   .awsui-select-dropdown-option.awsui-select-dropdown-option-selected{
       background-color: $aws-white !important;
   }
   .awsui-select-dropdown-option-selectable:hover{
       background-color: $aws-lightGrey !important;
   }
   .label-txt{
        font-family: AmazonEmberRegular;
   }
    
}
