@import '../../styles/variables.scss';
.deal-wraper{
    .commitfee-dollar-input{
        position: relative;
        top: -5px;
    }
    .Polaris-TextField{
        line-height: 1.4rem;
    }
    .Polaris-TextField__Backdrop{
        background-color: transparent;
    }
    .small-input{
        width: 10%;
        margin-top: 0;
        padding-right: 0;
        
        .AWS-input-div .input-label{
            margin-bottom: 0;
        }
        .Polaris-TextField__Input{
            line-height: 1.4rem;
            min-height: 2.2rem;
            text-align: center;
            color: $aws-grey;
            border: none;
        }
    }
    .commit-txt{
        background-color: $aws-white;
        border-radius: 4px;
        text-align: center;
        padding: 5px;
        .dollar-txt{
            border-right: 1px solid $aws-grey;
            padding-right: 10px;
            margin-right: 5px;
        }
        span{
            float: left;
        }
        .AWS-input-div{
            float: left;
            width: 74%;
            input{
                border: none;
                height: 20px;

            }
        }
        
    }
    .disable-state.commit-txt{
        background-color: #eaeded;
    }
    .total-wraper{
        input{
            height: 20px;
        }
        .commit-txt{
            padding: 5px;
        }
        
    }
    .width130{
        width: 129.5px;
    }
    .width65{
        width: 65%;
    }
    .width78{
        width: 78%;
    }
    .green-border{
        border: 1px solid $aws-lightGreen;
    }
    .tiered-input{
        width: 25%;
        input{
            padding: 1px;
            width: 100%;
            font-size: 12px;
            height: auto;
            text-align: center;
        }
        
    }
    .anticon svg{
        width: 10px;
    }
    .revenue-txt{
        .has-error{
            width: 24%;
            .error-icon{
                position: relative;
                top: 0;
                .Polaris-Icon{
                    top: 4px;
                }
            }
        }
    }
    .width97{
        width: 97%;
    }
    .load-btnwraper{
        .awsui-icon.awsui-icon-size-normal svg{
            height: 14px !important;
            right: 5px;
        }
        .awsui-button{
            border-radius: 4px !important;
            padding: 0.4rem 1.74rem !important;
            font-size: 12px !important;
        }
        .awsui-icon svg *{
            stroke: $aws-primaryColor !important;
        }
    }
    .load-btnwraper.disable-undo{
        .awsui-icon svg *{
            stroke: $aws-lightGrey !important;
        }
    }
    .blue-link{
        color: $aws-darkBlue;
        text-decoration: underline;
        cursor: pointer;
    }
    .table-border{
        border: .5px solid $aws-grey;
        text-align: center;
        background: #CECECE39 0% 0% no-repeat padding-box;
    }
    .input-style{
        .Polaris-TextField__Input{
            border-radius: 0;
            line-height: 1.4rem;
            text-align: center;
            font-size: 13px;
            min-height: 1.6rem;
        }
    }
    .widthreggioanl{
        display: table;
        height: 42px;
        .grey-txt{
            display: table-cell;
            vertical-align: middle;
        }
    }
    .without-tiering-input{
        .Polaris-TextField__Input{
            padding: 5px;
        }
    }
    .tiering-input{
        .Polaris-TextField__Input{
            padding: 5px;
        }
    }
    .width7p3{
        width: 8.2% ;
        .awsui-input{
            padding: 4px;
            font-size: 13px;
        }
    }
    .width9p3{
        width: 9.3% ;
    }
    .max-width69{
        max-width: 67.6%;
    }
    .table-icons{
        img{
            width: 14px;
            cursor: pointer;
            position: relative;
            top: 4px;
        }
    }
    .pricing-textarea{
        .Polaris-Labelled__LabelWrapper{
            color: $aws-grey;
        }
    }
    .pricing-plusminus{
        position: relative;
        top: -2px;
        cursor: pointer;
    }
    .additionterm-dropdown{
        .drop-wraper{
            top: -5px;
            .disinline{
                display: block;
            }
        }
    }
    
}
/*media query */
@media (min-width: 1280px){
    .deal-wraper{
        .toggle-txt{
            max-width: 71.6%;
        }
        .width7p3 {
            width: 7.1%;
        }
        .regional-table{
            .width9p3 {
                width: 10.3%;
            }
        }
        .requestfee-table{
            .width7p3 {
                width: 8.38%;
            }
        }
        .width78{
            width: 82%;
        }
        .width9p3 {
            width: 9.25%;
        }
        .width9p3:last-child {
            width: 10.36%;
            margin-right: 0 !important;
        }
        .width97 {
            width: 98%;
        }
        .regional-mixtable{
            .width9p3 {
                width: 10.25%;
            }
        }
        .max-width69 {
            max-width: 70%;
        }
        .request-alert{
            width: 89%;
        }
        .regional-alert{
            width: 82%;
        }
        .width78.load-btnwraper{
            width: 82.5%;
        }
    }
}

@media (min-width: 1366px){
    .deal-wraper{
        .toggle-txt{
            max-width: 74%;
        }
        .max-width69 {
            max-width: 69.6%;
        }
        .width7p3 {
            width: 7.2%;
        }
        .width9p3 {
            width: 9.33%;
        }
        .width78 {
            width: 89%;
        }
        .width97 {
            width: 97%;
            .width9p3:last-child{
                margin-right: 0 !important;
                width: 10%;
            }
        }
        .requestfee-table{
            .width7p3 {
               width: 7.73%;
           }
       }
       .regional-table{
           .width9p3 {
               width: 10.1%;
           }
           .width9p3:last-child{
               margin-right: 0 !important;
               width: 10%;
           }
       }
       .width78.load-btnwraper{
            width: 82.6%;
       }
       .regional-mixtable .width9p3 {
            width: 10.1%;
        }
        .request-alert {
            width: 90%;
        }
        .regional-alert{
            width: 81.6%;
        }
    }
    
}
@media (min-width: 1400px){
    .deal-wraper{
        .width7p3{
            width: 7.3%;
        }
        .requestfee-table {
            .width7p3 {
                width: 7.6%;
            }
        }
        .width97 {
            width: 96%;
        }
        .max-width69 {
            max-width: 68.6%;
        }
        .regional-table {
            .width9p3 {
                width: 10.24%;
            }
        }
        .width78.load-btnwraper {
            width: 80.6%;
        }
    }
}
@media (min-width: 1599px){
    .deal-wraper{
        .toggle-txt{
            max-width: 73.3%;
        }
        .width97 {
            width: 93.9%;
            .width9p3:last-child {
                margin-right: 0 !important;
                width: 11%;
            }
        }
        .width130 {
            width: 128px;
        }
        .max-width69 {
            max-width: 67.7%;
        }
        .width9p3 {
            width: 10.1%;
        }
        .tiering-table{
            .width9p3 {
                width: 9.3%;
            }
        }
        .width7p3 {
            width: 7.3%;
        }
        .requestfee-table{
            .width7p3 {
                width: 7.6%;
            }
        }
        .width78.load-btnwraper{
            width: 80%;
       }
       .regional-mixtable .width9p3 {
            width: 10%;
        }
        .request-alert {
            width: 87%;
        }
        .regional-alert{
            width: 80%;
        }
    }
}
@media (min-width: 1680px){
    .deal-wraper{
        .width130 {
            width: 134px;
        }
    }
    .width97 .width9p3:last-child {
        margin-right: 0 !important;
        width: 11.3%;
    }
    .regional-table .width9p3 {
        width: 10.2%;
    }
}
@media (min-width: 1920px){
    .deal-wraper{
        .toggle-txt{
            max-width: 72%;
        }
        .width7p3 {
            width: 7.6%;
        }
        .regional-table{
            .width9p3 {
                width: 10.14%;
            }
        }
        .width97 {
            width: 91.9%;
            .width9p3:last-child {
                margin-right: 0 !important;
                width: 11%;
            }
        }
        .max-width69 {
            max-width: 65.7%;
        }
        .tiering-table{
            .width9p3 {
                width: 9.4%;
            }
        }
        .requestfee-table {
            .width7p3 {
                width: 7.4%;
            }
        }
        .width78.load-btnwraper{
            width: 77%;
        }
        .request-alert {
            width: 84%;
        }
        .regional-alert{
            width: 77.2%;
        }
    }
}
