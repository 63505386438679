@import '../../../styles/variables.scss';
.leftnav-tabs{
    .tabs-contanier{
        position: relative;
        margin-bottom: 0;
        padding-left: 1.5rem !important;
        padding-right: 0;
        .tabs-wraper{
            // line-height: 0;
            // border-bottom: .5px solid $aws-grey;
            padding-right: 0 !important;
            .tabs{
                span{
                    padding-bottom: 0.25rem !important;
                }
            }
        }
        .border-btm-tabsummary{
            border-bottom: 1px solid $aws-grey;
            position: absolute;
            top: 28.7px;
            z-index: 1;
        }
        .short-width-line{
            left: 14px;
            width: 99%
            }
        .tabs{
            display: inline-block;
            cursor: pointer;
            width: auto;
            margin-right: 20px;
            margin-top: 0;
            span{
                border-bottom: 0.3rem solid transparent;
            }
        }
        span.active-tab{
            border-bottom: 0.3rem solid $aws-primaryColor;
            font-family: AmazonEmberRegular;
        }
        .youtube-icon{
            img{
                // width: 24px;
                margin-left: 5px;
            }
            
        }
        .leftnav-summary-youtube{
            position: relative;
            top: 1.97px;
            .leftnav-summary-tooltip{
                position: absolute;
                left: 0;
                top: -18px;
                background-color:$aws-white;
                padding: 2px 6px;
                border-radius: 4px;
                font-size: 12px;
                display: none;
                z-index: 1;
                width: max-content;
                color: $aws-darkBlack;
                padding-bottom: 0;
                border-bottom: 0;
                line-height: 1.4;
            }
    
            // .comments-history-tooltip{
            //     left: 296px;
            // }
    
            // .attachments-tooltip{
            //     left: 440px;
            // }
    
            // top: 49px;
            //position: absolute;
            z-index: 7;
            .video-icon{
                height: 15px;
            }
        }
    
        .leftnav-summary-youtube:hover > .leftnav-summary-tooltip{
            display: block;
        }
    }
}
