@import '../../../styles/variables.scss';
.button-img-wraper{
    li{
        list-style: none;
        color: $aws-grey;
        background-color: $aws-white;
        border: .5px solid $aws-lightGrey;
        font-size: 14px;
        padding: 8px 15px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        width: 25.7%;
        height: auto;
        margin-bottom: 20px;
        margin-right: 20px;
        display: inline-block;
        text-align: center;
        padding-top: 8px !important;
        .tick-img{
            position: absolute;
            top: -12px;
            right: -8px;
            img{
                width: 20px;
            }
            
        }
    }
    li.active:last-child{
        border-right: .5px solid $aws-primaryColor;
    }
    .active{
        border: .5px solid $aws-primaryColor;
        background-color: $aws-primaryColor;
        color: #ffffff;
    }
    
    .disable-state{
        li{
            pointer-events: none;
            background-color: #eaeded;
            border: 0.5px solid #eaeded;
        }
        .active{
            background-color: rgba(255, 157, 0, 0.4);
            border: 0.5px solid rgb(247, 208, 145);
        }
    }
}
