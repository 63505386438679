.dashboard-submenu{
    background-color: #243143;
    // background-color:rgba(29,40,55,0.9);
    // width: 250px;
    float: left;
    position: absolute;
    top: 74px;
    z-index: 9;
    height: 100vh;
    li{
        display: flex-root;
    }
    cursor: pointer;
    // opacity: .95;
    .border-btm{
        border-bottom: 1px solid #aba6a6;
    }
    .border-none{
        border-bottom: 1px solid transparent;
        img{
            width: 16px;
        }
    }
    .termination-icon{
        width: 16px;
    }
    
    a:hover{
        text-decoration: none;
    }
}
.dashboard-wrapper{
    position: absolute;
    z-index: 99999999999;
    width: 100%;
    height: 100%;
    display:block;
}