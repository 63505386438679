@import '../../../styles/variables.scss';
.tabs-contanier{
    position: relative;
    top: 2px;
    ul{
        min-width: 60vw;
        overflow-x: auto;
        width: auto;
        white-space: nowrap;
        margin-right: 10px;
    }
    .Polaris-Tabs__Title{
        color: $aws-darkBlack;
        font-size: 16px;
        padding-top: 0;
    }
    .Polaris-Tabs{
        border-bottom: none;
    }
    .Polaris-Tabs__Tab:focus .Polaris-Tabs__Title,
    .Polaris-Tabs__Tab:hover .Polaris-Tabs__Title{
        color: $aws-darkBlack;
        border-bottom: .3rem solid $aws-darkBlack;
    }
    .Polaris-Tabs__Tab:focus{
        box-shadow: none;
    }
    .tablist{
        width: max-content;
    }
    .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
        border-bottom: 0.3rem solid $aws-primaryColor;
    }
    .icons-styles{
        background-repeat: no-repeat;
        padding: 8px 6px;
        background-size: 12px;
        float: left;
        margin-left: 5px;
        margin-top: 4px;
    }
    .clone-icon{
        background-size: 14px;
        margin-left: 0;
        padding: 10px;
    }
    li{
        display: inline-block;
        cursor: pointer;
    }
    li:last-child{
        margin-right: 0 !important;
    }
    .active-tab{
        border-bottom: 0.3rem solid $aws-primaryColor;
        font-family: AmazonEmberBold;
    }
    .Polaris-TextField__Input{
        min-height: 2.6rem;
        padding: 0.2rem 1.2rem;
        line-height: 1.4rem;
    }
    ::-webkit-scrollbar{
        width: 5px;
        height: 14px !important;
        background: transparent;
    }
    ::-webkit-scrollbar-thumb{
        border-radius: 14px;
        background: $aws-grey;
    }
}
