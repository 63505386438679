@import '../../styles/variables.scss';
.other-pricing-youtube{
    .other-pricing-tooltip{
        position: absolute;
        left: 272px;
        top: -22px;
        background-color: $aws-white;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        display: none;
        z-index: 1;
        width: max-content;
    }
    .additional-dealterm-tooltip{
        left: 10px;
    }

    top: 49px;
    //position: absolute;
    z-index: 7;
    .video-icon{
        // height: 15px;
    }
}
.other-pricing-youtube:hover > .other-pricing-tooltip{
    display: block;
}
.commit-youtube{
    position: relative;
    .commit-tooltip{
        position: absolute;
        left: 0;
        top: -22px;
        background-color: #fff;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        display: none;
        z-index: 1;
        width: max-content;
    }
    .no-commit-tooltip{
        left: 0;
    }
     //position: absolute;
    z-index: 7;
    .video-icon{
        // height: 15px;
    }
}
 
.commit-youtube:hover > .commit-tooltip{
    display: block;
}
 
.request-fees-youtube{
    .request-fees-tooltip{
        position: absolute;
        //right: 0;
        top: -22px;
        background-color: #fff;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        left: 30px;
        display: none;
        z-index: 1;
        width: max-content;
    }
 
    top: 0;
    //position: absolute;
    z-index: 7;
    .video-icon{
        // height: 15px;
    }
}
 
.request-fees-youtube:hover > .request-fees-tooltip{
    display: block;
}
 
.pricing-youtube{
    .pricing-tooltip{
        position: absolute;
        //right: 0;
        top: -12px;
        background-color: #fff;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        display: none;
        z-index: 1;
        width: max-content;
    }
 
    top: 49px;
    //position: absolute;
    z-index: 7;
    .video-icon{
        // height: 15px;
    }
}
 
.pricing-youtube:hover > .pricing-tooltip{
    display: block;
}

.regional-limits-youtube{
    .regional-limits-tooltip{
        position: absolute;
        //right: 0;
        top: -10px;
        background-color: #fff;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 12px;
        display: none;
        z-index: 1;
        width: max-content;
        font-family: AmazonEmberRegular;
    }

    top: 0;
    //position: absolute;
    z-index: 7;
    .video-icon{
        // height: 15px;
    }
}

.regional-limits-youtube:hover > .regional-limits-tooltip{
    display: block;
}
@media (min-width: 1500px){
    body .rte-col{
        padding-left: 9px !important
    }
    }
    @media (min-width: 1600px){
    body .rte-col{
        padding-left: 0px !important
    }
    }
    @media (min-width: 1650px){
    body .rte-col{
        padding-left: 5px !important
    }
    }
     
    @media only screen and (max-width:1270px) and (min-width: 1025px){
     
        body .rte-col{
            zoom: 89%;
        }
        .deal-wraper .width9p3{
            zoom: 90%;
        }
     
    }