@import '../../../styles/variables.scss';
.watchlist-table{
    .admin-table {
        .tabulator .tabulator-header .tabulator-col .tabulator-col-content {
            border-right: none;
        }
    }
}
.watchlist-popup{
    width: 72%;
    margin: auto;
    background-color: $aws-white;
    .header-wraper{
        background-color: $aws-adminpopupHeaderBg;
    }
    .addaccount-plus{
        padding: 3.5px 9px;
        border-radius: 4px;
        border: .5px solid $aws-lightGrey;
        .Polaris-Icon{
            width: 1.4rem;
            position: relative;
            top: 4px;
        }
        
    }
    .footer-wraper{
        border-top: 1px solid $aws-lightGrey;
    }
    .accountname-list{
        .list-wraper{
            display: inline-block;
            margin-bottom: 10px;
        }
        .list-wraper:last-child{
            margin-bottom: 0;
        }
    }
}
/*additional deal terms*/
.additionl-listwraper{
    li{
        display: inline-block;
        border-bottom: 1px solid $aws-lightGrey;
    }
}