@import '../../styles/variables.scss';
.termination-wraper{
    .tick-img,.dot-lines,
    .nav-step{
        display: none;
    }
    .nav-wrapper .nav-container{
        width: auto;
    }
    .nav-wrapper li .nav-txt{
        width: 100%;
    }  
    a:hover{
        text-decoration: none;
    }
    .summary-context .btns.unfill-btn .Polaris-Button:hover{
        background-color: $aws-primaryColor;
        color: $aws-white;
    }

    .error-icon .Polaris-Icon{
        top: 3px;
        height: 1.3rem !important;
        width: 1.5rem !important;
    }

    .date-wraper{
        float: left;
        width: 100%;
        margin-top: 5px;
        .Polaris-Icon{
            top: 5px;
        }
        .calendar-icon {
            top: 38px;
            cursor: pointer;
        }
        .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
            width: 7rem;
        }
        .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range{
            background-color: $aws-primaryColor;
        }
        .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
            padding: 3px;
        }
        .react-datepicker__header{
            padding-bottom: 8px;
        }
        .react-datepicker-year-header{
            font-size: 12px;
        }
    }
    .termination-youtube{
        .termination-tooltip{
            position: absolute;
            left: 120px;
            top: -16px;
            background-color: #fff;
            padding: 1px 6px;
            border-radius: 4px;
            font-size: 12px;
            display: none;
            z-index: 1;
            width: max-content;
            font-family: AmazonEmberRegular;
        }
     
        top: 49px;
        //position: absolute;
        z-index: 7;
        .video-icon{
            height: 15px;
        }
    }
     
    .termination-youtube:hover > .termination-tooltip{
        display: block;
    }
}
.termination-popup{
    .ant-modal-body{
        padding: 0;
    }
    .content-wraper{
        background-color: $aws-darkBlack;
    }
    .ant-modal-close-x{
        display: none;
    }
    .ant-modal-content{
        border: none;
    }
    .ant-modal {
        width: 620px !important;
    }
    .button-wraper{
        background-color: $aws-white;
    }
  
}
.Polaris-Modal-Dialog__Modal {
    max-width: 60rem !important;
}
/* media query for 1280 */
@media only screen and (max-width: 1280px) {
    .font-size14 {
        font-size: 13px !important;
    }
}