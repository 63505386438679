@import '../../../styles/variables.scss';
.AWS-input-div{
    .input-label{
        font-family: AmazonEmberRegular;
        font-size: 14px;
        color: $aws-darkBlack;
        margin-bottom: .5rem;
    }
    .orangeborder-input{
        input{
            border: .5px solid $aws-primaryColor;
        }
    }
    input{
        background: $aws-white 0% 0% no-repeat padding-box;
        border: .5px solid $aws-lightGrey;
        font-family: AmazonEmberRegular;
    } 
    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
    .awsui-input{
        border: .5px solid var(--awsui-color-border-input-default);
    }
    .awsui-input:focus{
        outline: 1px dotted transparent;
    }
    // .Polaris-TextField__Backdrop {
    //     border: none;
    // }
    // .Polaris-TextField__Backdrop:after {
    //     border: var(--p-focus-ring-stroke, .1rem) solid var(--p-interactive-focus, $aws-primaryColor);
    // }
    // .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after{
    //     border-color: $aws-red;
    // }
}
.orangeborder-input{
    .AWS-input-div input{
        border: 1px solid $aws-primaryColor;
    }
    .AWS-input-div input:focus{
        border: 1px solid transparent;
    }
   
}

/*border input*/
.border-input-wraper{
    position: relative;
    float: left;
    width: auto;
    margin-bottom: 4px;
    .mandatory-wraper{
        position: absolute;
        left: -5px;
        top: 32px;
        width: 160px;
    }
}
