@import '../../styles/variables.scss';

.login-wraper{
    position: absolute;
    top: 33vh;
    left: 24%;
    padding: 20px 30px 20px;
    border-radius: 4px;
    width: 50%;;
    .logo{
        display: flex;
        .logo-container{
            margin: 0 auto;
            .img-logo{
                width: 120px;
            }
            // .logo-img{
            //     height: 25px;
            //     display: inline-block;
            //     vertical-align: middle;
            //     position: relative;
            //     top: -.45px;
            // }
            // .logo-title{
            //     display: inline-block;
            //     vertical-align: middle;
            //     position: relative;
            //     top: -7.06px;
            //     display: none;
            // }
        }
    }
    button{
        margin: 0 auto;
        display: block;
        // margin-top: 8px;
    }
    .midway{
        display: flex;
        .welcome-msg{
            padding: 10px;
            color: #747474;
            font-style: italic;
            border-radius: 4px;
            text-align: center;
        }
    }
}
.bg-img{
    .map-img{
        margin: 0 auto;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.request-access-wraper{
    margin: 0 auto;
    width: 120px;
    a{
        color: $aws-darkBlue !important;
    }
    .link-txt{
        display: flex;
        align-items: center;
        color: $aws-darkBlue;
    }
}
.another-login{
    color: $aws-primaryColor;
    cursor: pointer;
}


.user-name{
    background-color: transparent !important;
}