@import '../../../styles/variables.scss';
.ppr-wraper{
    .sub-controls{
        width: 230px;
        li{
            display: inline-block;
            cursor: pointer;
            .awsui-icon.awsui-icon-size-normal svg{
                height: 13px;
                top: 0px;
            }
        }
    }
}
.awsui-util-container > :last-child{
    padding-bottom: 0;
}

